import * as React from "react";
import { IContentProps } from "./Modal";
import styled from "styled-components";
import { pr } from "../../AppTheme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3,
  p {
    color: white;
    text-align: center;
  }

  h3 {
    font-size: ${pr(28)};
  }

  p {
    font-size: ${pr(24)};
  }

  div.btn {
    padding: ${pr(18)} 0;
  }
`;

export const NotifyModal: React.FC<
  IContentProps & { title: string; para: string }
> = ({ onClose: handleClose, title, para }) => {
  return (
    <Container>
      <div className="text">
        <h3>{title}</h3>
        <p>{para}</p>
      </div>
      <div className="btn">
        <button onClick={handleClose}>Close</button>
      </div>
    </Container>
  );
};
