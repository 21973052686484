import * as React from "react";
import Countdown from "react-countdown";
import styled from "styled-components";
import { pr } from "../AppTheme";
import { ConnectButton } from "../Components/ConnectButton";
import { useBabyGeistContext } from "../Contexts/BabyGeistContext";
import { useWeb3React } from "../Hooks/useWeb3React";
import { Modal } from "../Components/Modals/Modal";
import { LoadingModal } from "../Components/Modals/LoadingModal";
import { TransactionSubmitted } from "../Components/Modals/TransactionSubmitted";
import { NotifyModal } from "../Components/Modals/NotifyModal";
import ghosty_background from "../Content/ghosty_background.png";
import ghostyPreviews from "../Content/ghosty-previews.gif";
import { utils } from "ethers";

const Container = styled.div<{ enter: boolean }>`
  background-image: url(${ghosty_background});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  min-height: calc(100vh - 6.5rem);
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border: none;
    border-radius: 10px;
    font-size: ${pr(28)};
    padding: ${pr(10)} ${pr(48)};
    cursor: pointer;
    color: white;

    &.claim {
      width: auto;
      margin: 0 auto;
    }

    :hover {
      background-color: ${({ theme }) => theme.palette.primary.dark};

      :not(:disabled):active {
        background-color: ${({ theme }) => theme.palette.primary.main};
      }
    }

    &:disabled {
      cursor: auto;
      background-color: ${({ theme }) => theme.palette.secondary.dark};
      opacity: 0.2;
    }
  }

  .content {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .mint-box,
  .claim-box {
    background-color: white;
    border-radius: ${pr(40)};
    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 50%);
    padding: ${pr(60)} ${pr(40)};
    transition: opacity 1s ease-in-out;
    opacity: ${({ enter }) => (enter ? 1 : 0)};
    margin: ${pr(20)};
    flex-basis: 50%;
  }

  .claim-box {
    width: calc(${pr(375)} + 10px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: ${pr(21)};
      text-align: center;

      &.small-text {
        font-size: ${pr(16)};
        font-style: italic;
        margin: ${pr(32)} 0 0;
      }
    }

    p.title {
      font-family: "Gluten", cursive;
      font-weight: bold;
      font-size: ${pr(42)};
      margin: 0;
    }

    .to-claim {
      padding: ${pr(18)} 0 ${pr(36)};

      p {
        margin: ${pr(8)} 0;
        font-weight: bold;
        text-align: center;
        font-size: ${pr(24)};

        &.pending-rewards {
          font-size: ${pr(32)};
          word-break: break-all;
        }
      }
    }
  }

  .mint-box {
    button {
      margin: ${pr(24)} 0;
      font-size: ${pr(32)};
      width: 100%;

      span {
        color: white;
      }
    }

    p {
      text-align: center;
      margin: 0;
      font-size: ${pr(21)};

      &.minted-name {
        font-size: ${pr(28)};
        margin-bottom: ${pr(14)};
      }
    }

    div.preview-box {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        opacity: 0;
        transition: opacity 1s ease-out;
        width: ${pr(375)};
        border: 5px solid ${({ theme }) => theme.palette.secondary.main};

        &.minted-elemental {
          position: absolute;
          transition: opacity 5s ease-in;
        }

        &.showing {
          opacity: 1;
        }
      }

      div.loader {
        position: absolute;
        transform: scale(2);

        .lds-default {
          display: inline-block;
          position: relative;
          width: 80px;
          height: 80px;
        }
        .lds-default div {
          position: absolute;
          width: 6px;
          height: 6px;
          background: ${({ theme }) => theme.palette.secondary.main};
          border-radius: 50%;
          animation: lds-default 1.2s linear infinite;
        }
        .lds-default div:nth-child(1) {
          animation-delay: 0s;
          top: 37px;
          left: 66px;
        }
        .lds-default div:nth-child(2) {
          animation-delay: -0.1s;
          top: 22px;
          left: 62px;
        }
        .lds-default div:nth-child(3) {
          animation-delay: -0.2s;
          top: 11px;
          left: 52px;
        }
        .lds-default div:nth-child(4) {
          animation-delay: -0.3s;
          top: 7px;
          left: 37px;
        }
        .lds-default div:nth-child(5) {
          animation-delay: -0.4s;
          top: 11px;
          left: 22px;
        }
        .lds-default div:nth-child(6) {
          animation-delay: -0.5s;
          top: 22px;
          left: 11px;
        }
        .lds-default div:nth-child(7) {
          animation-delay: -0.6s;
          top: 37px;
          left: 7px;
        }
        .lds-default div:nth-child(8) {
          animation-delay: -0.7s;
          top: 52px;
          left: 11px;
        }
        .lds-default div:nth-child(9) {
          animation-delay: -0.8s;
          top: 62px;
          left: 22px;
        }
        .lds-default div:nth-child(10) {
          animation-delay: -0.9s;
          top: 66px;
          left: 37px;
        }
        .lds-default div:nth-child(11) {
          animation-delay: -1s;
          top: 62px;
          left: 52px;
        }
        .lds-default div:nth-child(12) {
          animation-delay: -1.1s;
          top: 52px;
          left: 62px;
        }
        @keyframes lds-default {
          0%,
          20%,
          80%,
          100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.5);
          }
        }
      }
    }
  }
`;

export const MintGhosty: React.FC = () => {
  const {
    state,
    mintGhosty,
    getMintedGhosty,
    closeNotification: handleNotificationClose,
    closePending: handleClosePending,
    closeSubmittedTx: handleSubmittedTxClose,
    setGetGhostyData,
    claimGhostyRewards,
  } = useBabyGeistContext();
  const {
    ghostyMaxSupply,
    ghostyMinted,
    ghostyNextMintPrice,
    ghostyRemaining,
    ghostyStartTime,
    pendingOpen,
    submittedTx,
    notification,
    notificationTitle,
    ghostyPendingRewards,
  } = state;
  const { account } = useWeb3React();
  const [started, setStarted] = React.useState(false);
  const [mintedImg, setMintedImg] = React.useState("");
  const [showPreview, setShowPreview] = React.useState(true);
  const [pending, setPending] = React.useState(false);
  const [pendingClaim, setPendingClaim] = React.useState(false);
  const [mintedName, setMintedName] = React.useState("");
  const [enter, setEnter] = React.useState(false);

  React.useEffect(() => {
    setGetGhostyData(true);

    return () => setGetGhostyData(false);
  }, [setGetGhostyData]);

  React.useEffect(() => {
    requestAnimationFrame(() => setEnter(true));
  }, []);

  React.useEffect(() => {
    if (ghostyStartTime && Date.now() >= ghostyStartTime) {
      if (!started) {
        setStarted(true);
      }
    } else if (started) {
      setStarted(false);
    }
  }, [ghostyStartTime, started]);

  const handleComplete = React.useCallback(() => {
    setStarted(true);
  }, []);

  const handleMint = React.useCallback(async () => {
    setPending(true);
    if (
      await mintGhosty(() => {
        setShowPreview(true);
      })
    ) {
      setShowPreview(false);
    }
    setPending(false);
  }, [mintGhosty]);

  const handleClaim = React.useCallback(async () => {
    setPendingClaim(true);
    if (!(await claimGhostyRewards(() => setPendingClaim(false)))) {
      setPendingClaim(false);
    }
  }, [claimGhostyRewards]);

  React.useEffect(() => {
    if (ghostyMinted) {
      setPending(false);
      getMintedGhosty().then((meta) => {
        setMintedImg(meta["image"]);
        setMintedName(meta["name"]);
      });
    }
  }, [ghostyMinted, getMintedGhosty]);

  const pendingFormatted = React.useMemo(() => {
    if (!ghostyPendingRewards) return "-";
    const formatted = utils.formatEther(ghostyPendingRewards);
    return Number(
      formatted.substring(0, formatted.indexOf(".") + 3)
    ).toLocaleString();
  }, [ghostyPendingRewards]);

  function buttonText() {
    if (!ghostyStartTime || pending) return "Pending";
    if (!started) {
      return (
        <Countdown
          date={ghostyStartTime}
          daysInHours
          onComplete={handleComplete}
        />
      );
    }
    if (ghostyRemaining === 0) return "Sold Out!";

    return `Mint - ${
      ghostyNextMintPrice
        ? ghostyNextMintPrice.eq(0)
          ? "FREE!"
          : `${utils.formatEther(ghostyNextMintPrice)} FTM`
        : "---"
    }`;
  }

  function renderButton() {
    if (account) {
      return (
        <button
          disabled={!started || pending || ghostyRemaining === 0}
          onClick={handleMint}
        >
          {buttonText()}
        </button>
      );
    }

    return <ConnectButton />;
  }

  function renderClaimButton() {
    if (account) {
      return (
        <button
          disabled={pendingClaim || ghostyPendingRewards?.eq(0)}
          onClick={handleClaim}
          className="claim"
        >
          Claim
        </button>
      );
    }

    return <ConnectButton />;
  }

  return (
    <>
      <Modal
        open={pendingOpen}
        onClose={handleClosePending}
        content={(props) => (
          <LoadingModal {...props} text="Pending Transaction" />
        )}
      />
      <Modal
        open={submittedTx !== ""}
        onClose={handleSubmittedTxClose}
        content={(props) => (
          <TransactionSubmitted
            {...props}
            para={notification}
            tx={submittedTx}
          />
        )}
      />
      <Modal
        open={notification !== ""}
        onClose={handleNotificationClose}
        content={(props) => (
          <NotifyModal
            {...props}
            para={notification}
            title={notificationTitle}
          />
        )}
      />
      <Container enter={enter}>
        <div className="content">
          <div className="mint-box">
            {mintedName && <p className="minted-name">{mintedName}</p>}
            <div className="preview-box">
              <img
                className={showPreview ? "showing" : ""}
                alt=""
                src={ghostyPreviews}
              />

              {!showPreview && (
                <div className="loader">
                  <div className="lds-default">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}

              <img
                className={`minted-elemental${mintedImg ? " showing" : ""}`}
                alt=""
                src={mintedImg}
              />
            </div>
            {renderButton()}
            <p>
              {ghostyRemaining ?? "---"}/{ghostyMaxSupply ?? "---"} Remaining
            </p>
          </div>
          <div className="claim-box">
            <p className="title">Reflection Rewards</p>
            <p>
              15% of mint price is reflected back to
              <br />
              Ghosty holders.
            </p>
            <div className="to-claim">
              <p>Pending Rewards:</p>
              <p className="pending-rewards">{pendingFormatted} bGEIST</p>
            </div>
            {renderClaimButton()}
            <p className="small-text">
              Transferring will also claim your rewards.
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};
