import * as React from "react";
import styled from "styled-components";
import { IoIosArrowUp } from "react-icons/io";

const Container = styled.button`
  background-color: ${({ theme }) => theme.palette.primary.main};
  position: fixed;
  right: 25px;
  bottom: 25px;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: opacity 0.25s;
  border: none;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.75);

  svg {
    color: white;
    font-size: 32px;
  }
`;

export const ScrollToTop: React.FC = () => {
  const [isAtTop, setIsAtTop] = React.useState(true);

  const handleScroll = React.useCallback(() => {
    setIsAtTop(window.scrollY === 0);
  }, []);

  const handleClick = React.useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  React.useEffect(handleScroll, [handleScroll]);

  return (
    <Container
      onClick={handleClick}
      style={{
        opacity: isAtTop ? 0 : 1,
        pointerEvents: isAtTop ? "none" : "all",
      }}
    >
      <IoIosArrowUp />
    </Container>
  );
};
