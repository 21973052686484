import * as React from "react";
import styled from "styled-components";
import { pr } from "../AppTheme";
import background from "../Content/background_1.png";
import elementals_icon from "../Content/elementals_icon.png";
import ghosty_icon from "../Content/ghosty_icon.png";
import { __RouterContext } from "react-router";

const Container = styled.div`
  min-height: calc(100vh - 6.5rem);
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  padding: ${pr(42)} ${pr(86)};
  box-sizing: border-box;
  align-items: center;
  justify-content: space-around;
  justify-content: space-evenly;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  .mint {
    flex-basis: 40%;

    p {
      color: white;
      text-align: center;
      font-size: ${pr(24)};
      margin: ${pr(24)} 0;

      &.release {
        font-size: ${pr(42)};
      }
    }

    img {
      width: 100%;
      display: block;
      box-shadow: 0 1px 3px 1px rgb(0 0 0 / 50%);
      border: 2px solid black;
      transition: all 0.15s ease-out;

      :hover {
        transform: scale(1.02);
      }
    }
  }

  /* .ghosty img {
    filter: grayscale(100%);
    cursor: not-allowed;

    :hover {
      filter: none;
    }
  } */
`;

export const MintSelect: React.FC = () => {
  const { history } = React.useContext(__RouterContext);

  const handleSelectElementals = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      history.push("/mint/elementals");
    },
    [history]
  );

  const handleSelectGhosty = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      history.push("/mint/ghosty");
    },
    [history]
  );

  return (
    <Container>
      <div className="mint elementals">
        <p className="release">Out NOW!</p>
        <a href="/mint/elementals" onClick={handleSelectElementals}>
          <img alt="Elementals Collection" src={elementals_icon} />
        </a>
        <p>Free Giveaway For BabyBoo Kittens Holders!</p>
      </div>
      <div className="mint ghosty">
        <p className="release">Out NOW!</p>
        <a href="/mint/ghosty" onClick={handleSelectGhosty}>
          <img alt="Ghosty Collection" src={ghosty_icon} />
        </a>
        <p>Passive Income Opportunity!</p>
      </div>
    </Container>
  );
};
