import * as React from "react";
import { IContentProps } from "./Modal";
import styled from "styled-components";
import { pr } from "../../AppTheme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${pr(24)};

  p {
    color: white;
    text-align: center;
    font-size: ${pr(24)};
    margin: ${pr(24)} 0;
  }

  div.btn {
    padding-top: ${pr(8)};
  }

  div.loading {
    padding-top: ${pr(24)};

    @keyframes ldio-rx2ze3gxt8e {
      0% {
        top: 96px;
        left: 96px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: 18px;
        left: 18px;
        width: 156px;
        height: 156px;
        opacity: 0;
      }
    }
    .ldio-rx2ze3gxt8e div {
      position: absolute;
      border-width: 4px;
      border-style: solid;
      opacity: 1;
      border-radius: 50%;
      animation: ldio-rx2ze3gxt8e 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .ldio-rx2ze3gxt8e div:nth-child(1) {
      border-color: ${({ theme }) => theme.palette.primary.dark};
      animation-delay: 0s;
    }
    .ldio-rx2ze3gxt8e div:nth-child(2) {
      border-color: ${({ theme }) => theme.palette.primary.main};
      animation-delay: -0.5s;
    }
    .loadingio-spinner-ripple-javpvr949y {
      width: 200px;
      height: 200px;
      display: inline-block;
      overflow: hidden;
    }
    .ldio-rx2ze3gxt8e {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }
    .ldio-rx2ze3gxt8e div {
      box-sizing: content-box;
    }
  }
`;

export const LoadingModal: React.FC<IContentProps & { text: string }> = ({
  onClose: handleClose,
  text,
}) => {
  return (
    <Container>
      <div className="loading">
        <div className="loadingio-spinner-ripple-javpvr949y">
          <div className="ldio-rx2ze3gxt8e">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <p>{text}</p>
    </Container>
  );
};
