import * as React from "react";
import { useBabyGeistState } from "../Hooks/useBabyGeistState";
import { BabyGeistContext } from "./BabyGeistContext";

export const BabyGeistProvider: React.FC = ({ children }) => {
  const state = useBabyGeistState();

  return (
    <BabyGeistContext.Provider value={state}>
      {children}
    </BabyGeistContext.Provider>
  );
};
