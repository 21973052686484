import "styled-components";

interface IColour {
  main: string;
  dark: string;
}

interface ITheme {
  palette: {
    background: string;
    primary: IColour;
    secondary: IColour;
    extras: { [colour: string]: IColour };
  };
}

declare module "styled-components" {
  export interface DefaultTheme extends ITheme {}
}

export const DefaultTheme: ITheme = {
  palette: {
    background: "#E5F4FF",
    primary: { main: "#648B54", dark: "#4D683F" },
    secondary: { main: "#383D51", dark: "#333335" },
    extras: {},
  },
};

export const pr = (value: number) => `${value / 16}rem`;
