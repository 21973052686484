import * as React from "react";
import styled from "styled-components";
import { pr } from "../AppTheme";
import { Link } from "react-router-dom";
import bgeistLogo from "../Content/bgeist_logo-small.png";
import { __RouterContext } from "react-router";

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};

  div.header {
    padding: ${pr(24)} ${pr(48)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 5;
    max-width: 1240px;
    margin: auto;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    a {
      text-decoration: none;
    }

    div.logo {
      display: flex;
      align-items: center;

      img {
        width: ${pr(56)};
        padding-right: ${pr(10)};
      }
    }

    h1 {
      font-size: ${pr(42)};
      margin: 0;
      font-weight: 500;
      color: white;
    }

    div.right {
      display: flex;
    }

    div.links {
      display: flex;
      align-items: center;

      a {
        color: white;
        text-decoration: underline;
        font-size: ${pr(24)};
        margin-right: ${pr(28)};

        :hover {
          text-decoration: none;
        }
      }
    }

    button.claim-btn {
      background-color: ${({ theme }) => theme.palette.primary.main};
      border: none;
      border-radius: 10px;
      font-size: ${pr(28)};
      padding: ${pr(4)} ${pr(24)};
      cursor: pointer;
      color: white;

      :hover {
        background-color: ${({ theme }) => theme.palette.primary.dark};

        :active {
          background-color: ${({ theme }) => theme.palette.primary.main};
        }
      }
    }
  }
`;

export const Header: React.FC = () => {
  const { history } = React.useContext(__RouterContext);

  const handleClaimBtn = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (history.location.pathname !== "/") return;
      e.preventDefault();

      const el = document.getElementById("claim");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        history.push("#claim");
      }
    },
    [history]
  );

  const handleMint = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      history.push("/mint");
    },
    [history]
  );

  const handleStake = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      history.push("/stake");
    },
    [history]
  );

  return (
    <Container>
      <div className="header">
        <Link to="/">
          <div className="logo">
            <img alt="" src={bgeistLogo} />
            <h1>BabyGeist</h1>
          </div>
        </Link>
        <div className="right">
          <div className="links">
            <a href="/mint" onClick={handleMint}>
              Mint
            </a>
            <a href="/stake" onClick={handleStake}>
              Stake
            </a>
            <a
              href="https://spookyswap.finance/swap?outputCurrency=0x727465b34ee5c2f4a2b55083a326b3634d2da6b0"
              target="_blank"
              rel="noreferrer"
            >
              Buy
            </a>
            <a
              href="https://charts.zoocoin.cash/charts?exchange=SpookySwap&pair=0xC0c26556C6b41c08185a7f51a52A10B4D08BBDEa-inverted"
              target="_blank"
              rel="noreferrer"
            >
              Chart
            </a>
            <a
              href="https://babygeist.gitbook.io/docs/"
              target="_blank"
              rel="noreferrer"
            >
              Docs
            </a>
          </div>
          <a href="/#claim">
            <button className="claim-btn" onClick={handleClaimBtn}>
              Claim
            </button>
          </a>
        </div>
      </div>
    </Container>
  );
};
