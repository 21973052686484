import * as React from "react";
import styled from "styled-components";
import { pr } from "../AppTheme";

const Container = styled.div`
  padding: ${pr(48)} 0;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-top: ${pr(14)} solid ${({ theme }) => theme.palette.secondary.dark};

  > div {
    padding: 0 ${pr(48)};
    max-width: 1240px;
  }

  p {
    color: white;
    text-align: center;
    font-size: ${pr(36)};
  }
`;

export const Footer: React.FC = () => {
  const year = React.useMemo(() => new Date().getFullYear(), []);

  return (
    <Container>
      <p>© Baby Finance {year}</p>
    </Container>
  );
};
