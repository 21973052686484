import { UnsupportedChainIdError } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import * as React from "react";
import styled from "styled-components";
import { useInjected } from "../Contexts/InjectedContext";
import { useEagerConnect, useInactiveListener } from "../Hooks";
import { useChangeNetwork } from "../Hooks/useChangeNetwork";
import { useWeb3React } from "../Hooks/useWeb3React";

const Container = styled.button``;

export const ConnectButton: React.FC = () => {
  const { activate, account, connector, error } = useWeb3React();
  const injected = useInjected();
  const changeNetwork = useChangeNetwork();

  const [activatingConnector, setActivatingConnector] = React.useState<
    InjectedConnector | null | undefined
  >();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  const handleConnect = React.useCallback(() => {
    if (injected && !account) {
      setActivatingConnector(injected);
      activate(injected);
    }
  }, [activate, injected, account]);

  React.useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      changeNetwork();
    }
  }, [error, changeNetwork]);

  return (
    <Container onClick={handleConnect}>
      {account
        ? `${account.substring(0, 4)}...${account.substring(
            account.length - 4
          )}`
        : "Connect"}
    </Container>
  );
};
