import * as React from "react";
import { IContentProps } from "./Modal";
import styled from "styled-components";
import { pr } from "../../AppTheme";
import mascot from "../../Content/bgeist_mascot.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3,
  p {
    color: white;
    text-align: center;
  }

  h3 {
    font-size: ${pr(28)};
  }

  p {
    font-size: ${pr(24)};
  }

  img {
    width: 8vw;
    margin: 0 auto ${pr(24)};
  }

  a {
    display: block;
    text-align: center;
    color: white;
  }

  div.btn {
    padding: ${pr(32)} 0 ${pr(18)};
  }

  div.text {
    display: flex;
    flex-direction: column;
  }
`;

export const TransactionSubmitted: React.FC<
  IContentProps & { para: string; tx: string }
> = ({ onClose: handleClose, para, tx }) => {
  return (
    <Container>
      <div className="text">
        <h3>Transaction Submitted</h3>
        <img alt="" src={mascot} />
        {para && <p>{para}</p>}
        <a
          href={`https://ftmscan.com/tx/${tx}`}
          target="_blank"
          rel="noreferrer"
        >
          View on FTMScan
        </a>
      </div>
      <div className="btn">
        <button onClick={handleClose}>Close</button>
      </div>
    </Container>
  );
};
