import * as React from "react";
import styled from "styled-components";
import { useWeb3React } from "../Hooks/useWeb3React";
import { useBabyGeistContext } from "../Contexts/BabyGeistContext";
import { Modal } from "../Components/Modals/Modal";
import { NotifyModal } from "../Components/Modals/NotifyModal";
import { LoadingModal } from "../Components/Modals/LoadingModal";
import { TransactionSubmitted } from "../Components/Modals/TransactionSubmitted";
import { pr } from "../AppTheme";
import mascot from "../Content/bgeist_mascot.png";
import discordLogo from "../Content/Discord-Logo-White.svg";
import telegramLogo from "../Content/telegram-logo.svg";
import twtLogo from "../Content/twt-logo.svg";
import { ConnectButton } from "../Components/ConnectButton";
import { utils } from "ethers";
import tiktokLogo from "../Content/tiktok-48.png";
import githubLogo from "../Content/GitHub-Mark-Light-64px.png";
import background from "../Content/background_1.png";
import medium from "../Content/medium.png";
import elemental from "../Content/elemental.png";
import gh from "../Content/gh.png";
import { __RouterContext } from "react-router";

const Container = styled.div`
  width: 100%;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .mint-nft-btn {
    margin: ${pr(28)} auto;
  }

  button.buy {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border: none;
    border-radius: 10px;
    font-size: ${pr(28)};
    padding: ${pr(10)} ${pr(48)};
    cursor: pointer;
    color: white;

    :hover {
      background-color: ${({ theme }) => theme.palette.primary.dark};

      :active {
        background-color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  div.home-top {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    width: 100%;
    position: relative;
    overflow: hidden;

    img.mascot {
      width: calc(${pr(1240)} * 0.58);
      position: absolute;
      top: 4%;
      left: 48%;
    }

    > div {
      padding: ${pr(72)} ${pr(48)};
      max-width: 1240px;
      margin: auto;

      h2 {
        margin: 0;
        font-size: ${pr(64)};
        color: white;
      }

      p {
        margin: ${pr(36)} 0;
        font-size: ${pr(38)};
        color: white;
      }
    }

    @media (max-width: 487px) {
      > div {
        padding-bottom: calc(77.5rem * 0.22);
      }

      img.mascot {
        bottom: -50%;
        right: -30%;
        top: unset;
        left: unset;
      }
    }
  }

  div.social-media {
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.secondary.dark};
    padding: ${pr(8)} ${pr(48)};
    flex-wrap: wrap;

    a {
      padding: ${pr(8)} 0;
      font-size: ${pr(28)};
      display: flex;
      margin: 0 ${pr(28)};
      cursor: pointer;
      text-decoration: none;
      color: white;
      display: flex;
      align-items: center;

      img,
      svg {
        width: ${pr(28)};
        padding-right: ${pr(8)};
        height: auto;

        path,
        line {
          stroke: ${({ theme }) => theme.palette.secondary.dark};
        }
      }
    }
  }

  div.body {
    padding: ${pr(64)} ${pr(48)} ${pr(128)};
    max-width: 1240px;
    margin: auto;

    div.box {
      padding: ${pr(64)};
      background-color: white;
      margin: ${pr(64)} ${pr(-64)};
      border: 2px solid ${({ theme }) => theme.palette.secondary.main};
      box-shadow: 0 1px 3px 1px rgb(0 0 0 / 50%);

      @media (max-width: 1367px) {
        margin: 0;
      }
    }

    div.lower-boxes {
      display: flex;
      margin: 0 ${pr(-64)};

      @media (max-width: 1367px) {
        margin: 0;
        flex-direction: column;

        div.box {
          margin-top: ${pr(48)};
        }
      }

      @media (min-width: 1368px) {
        div.box {
          margin: 0;
          flex-basis: 50%;
        }

        > div:nth-of-type(1) {
          margin-right: ${pr(32)};
        }

        > div:nth-of-type(2) {
          margin-left: ${pr(32)};
        }
      }
    }

    h3 {
      font-size: ${pr(48)};
      margin: ${pr(16)} 0 ${pr(38)};
    }

    h4 {
      font-size: ${pr(36)};
      margin: ${pr(42)} 0 ${pr(18)};

      a {
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
      }
    }

    p,
    li {
      font-size: ${pr(24)};
    }

    li span {
      font-weight: 500;
    }

    div.nft-giveaway {
      display: flex;
      flex-direction: column;

      p span {
        font-weight: bold;
      }

      img {
        width: 12rem;
        border: 4px solid black;
      }

      h3 {
        margin-bottom: ${pr(8)};
      }

      h4.nft-staking {
        margin-top: ${pr(56)};
      }

      a.learn-more {
        font-weight: bold;
        font-size: ${pr(18)};
        display: block;
        margin-bottom: ${pr(24)};
      }

      div.nft-grid {
        display: flex;
        justify-content: space-around;
        justify-content: space-evenly;

        > div {
          flex-basis: 40%;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            text-align: center;
          }
        }
      }

      h3.giveaways {
        margin-top: ${pr(46)};
        margin-bottom: 0;
      }

      div.giveaway-table {
        display: flex;
        justify-content: space-around;
        justify-content: space-evenly;

        > div p,
        > div a,
        > div h4 {
          text-align: center;
        }
      }
    }

    div.what-is {
      display: flex;

      @media (max-width: 1367px) {
        margin-top: ${pr(48)};
      }

      @media (max-width: 767px) {
        flex-direction: column;
      }

      > div {
        flex-basis: 50%;

        p.slogan {
          font-size: ${pr(28)};
          font-weight: 500;
          margin-top: 0;
        }

        button.buy {
          margin: 0 0 ${pr(36)};
        }

        &.total-dividends {
          display: flex;
          justify-content: center;
          text-align: center;

          @media (max-width: 767px) {
            padding-top: ${pr(48)};
          }

          > div {
            padding-left: ${pr(64)};
            padding-right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @media (max-width: 767px) {
              padding: 0;
            }

            p {
              margin: 0;
              font-size: ${pr(42)};
            }

            p.tdd {
              margin: ${pr(32)} 0 0;
              font-size: ${pr(56)};
              font-family: "Gluten", cursive;
              font-weight: bold;
            }
          }
        }
      }
    }

    div.tokenomics {
      display: flex;
      flex-direction: column;

      ul {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 0;
      }
    }

    #claim {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        flex-grow: 1;
      }

      div.to-claim {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      p {
        margin: 0;
        font-size: ${pr(28)};
        text-align: center;

        &.geist {
          font-weight: 500;
          padding-top: ${pr(8)};
        }

        &.total {
          padding-top: ${pr(32)};
        }
      }

      div.claim-action {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: ${pr(64)};

        button {
          background-color: ${({ theme }) => theme.palette.primary.main};
          border: none;
          border-radius: 10px;
          font-size: ${pr(28)};
          padding: ${pr(10)} ${pr(48)};
          cursor: pointer;
          color: white;

          :hover {
            background-color: ${({ theme }) => theme.palette.primary.dark};

            :active {
              background-color: ${({ theme }) => theme.palette.primary.main};
            }
          }

          &:disabled {
            cursor: auto;
            background-color: ${({ theme }) => theme.palette.secondary.dark};
            opacity: 0.2;
          }
        }
      }
    }
  }
`;

export const Home: React.FC = () => {
  const {
    state,
    closeNotification: handleNotificationClose,
    closePending: handleClosePending,
    closeSubmittedTx: handleSubmittedTxClose,
    claim,
  } = useBabyGeistContext();
  const {
    toClaim,
    pendingOpen,
    submittedTx,
    notification,
    notificationTitle,
    totalDividends,
    userTotalDividends,
  } = state;
  const { account } = useWeb3React();
  const [pending, setPending] = React.useState(false);
  const { history } = React.useContext(__RouterContext);

  const handleClaim = React.useCallback(async () => {
    if (account) {
      setPending(true);
      await claim();
      setPending(false);
    }
  }, [account, claim]);

  const totalDividendsFormatted = React.useMemo(() => {
    if (!totalDividends) return "-";
    const formatted = utils.formatEther(totalDividends);
    return Number(
      formatted.substring(0, formatted.indexOf(".") + 3)
    ).toLocaleString();
  }, [totalDividends]);

  const toClaimFormatted = React.useMemo(() => {
    if (!toClaim) return "-";
    const formatted = utils.formatEther(toClaim);
    return Number(
      formatted.substring(0, formatted.indexOf(".") + 3)
    ).toLocaleString();
  }, [toClaim]);

  const userTotalDividendsFormatted = React.useMemo(() => {
    if (!userTotalDividends) return "-";
    const formatted = utils.formatEther(userTotalDividends);
    return Number(
      formatted.substring(0, formatted.indexOf(".") + 3)
    ).toLocaleString();
  }, [userTotalDividends]);

  const handleClickNFTs = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      window.scrollTo(0, 0);
      history.push("/mint");
    },
    [history]
  );

  const handleElementals = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      window.scrollTo(0, 0);
      history.push("/mint/elementals");
    },
    [history]
  );

  const handleGhosty = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      window.scrollTo(0, 0);
      history.push("/mint/ghosty");
    },
    [history]
  );

  const handleStakeClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      window.scrollTo(0, 0);
      history.push("/stake");
    },
    [history]
  );

  return (
    <>
      <Modal
        open={pendingOpen}
        onClose={handleClosePending}
        content={(props) => (
          <LoadingModal {...props} text="Pending Transaction" />
        )}
      />
      <Modal
        open={submittedTx !== ""}
        onClose={handleSubmittedTxClose}
        content={(props) => (
          <TransactionSubmitted
            {...props}
            para={notification}
            tx={submittedTx}
          />
        )}
      />
      <Modal
        open={notification !== ""}
        onClose={handleNotificationClose}
        content={(props) => (
          <NotifyModal
            {...props}
            para={notification}
            title={notificationTitle}
          />
        )}
      />
      <Container>
        <div className="home-top">
          <div>
            <h2>
              Baby Geist
              <br />
              Gets Geist
            </h2>
            <p>
              Hold $BabyGEIST, collect $GEIST
              <br />
              from transaction fees.
            </p>
            {/* <a
              href="https://spookyswap.finance/swap?outputCurrency=0x727465b34ee5c2f4a2b55083a326b3634d2da6b0"
              target="_blank"
              rel="noreferrer"
            >
              <button className="buy">Buy Now</button>
            </a> */}
            <a href="/mint" onClick={handleClickNFTs}>
              <button className="buy">NFTs OUT NOW!</button>
            </a>
          </div>
          <img className="mascot" alt="" src={mascot} />
        </div>
        <div className="social-media">
          <a
            href="https://discord.gg/cvNJdq2hJx"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="" src={discordLogo} />
            Discord
          </a>
          <a
            href="https://twitter.com/BabyGeistFTM"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="" src={twtLogo} />
            Twitter
          </a>
          <a href="https://t.me/babygeist" target="_blank" rel="noreferrer">
            <img alt="" src={telegramLogo} />
            Telegram
          </a>
          <a
            href="https://www.tiktok.com/@baby_geist"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="" src={tiktokLogo} />
            TikTok
          </a>
          <a
            href="https://ftmscan.com/token/0x727465b34ee5c2f4a2b55083a326b3634d2da6b0"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="73"
              height="73"
              viewBox="0 0 73 73"
            >
              <g transform="translate(-169 -216)">
                <g transform="translate(60 -96)">
                  <rect
                    width="73"
                    height="73"
                    rx="10"
                    transform="translate(109 312)"
                    fill="#fff"
                  />
                  <g transform="translate(-65.243 87.003)">
                    <g transform="translate(188.242 235.997)">
                      <path
                        d="M45.054,35.037V15.013a5.006,5.006,0,0,0-2.5-4.33L25.03.671a5.006,5.006,0,0,0-5.006,0L2.5,10.683A5.006,5.006,0,0,0,0,15.013V35.037a5.006,5.006,0,0,0,2.5,4.33L20.024,49.38a5.006,5.006,0,0,0,5.006,0L42.551,39.367A5.006,5.006,0,0,0,45.054,35.037Z"
                        transform="translate(0 0)"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                      />
                      <path
                        d="M0,0,21.851,12.64,43.7,0"
                        transform="translate(0.676 12.41)"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                      />
                      <line
                        y1="25.23"
                        transform="translate(22.527 25.025)"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            FTMScan
          </a>
          <a
            href="https://github.com/BabyFinance/BabyGeist-contracts"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="" src={githubLogo} />
            GitHub
          </a>
          <a
            href="https://babygeist.medium.com"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="" src={medium} className="medium" />
            Medium
          </a>
        </div>
        <div className="body">
          <div className="nft-giveaway box">
            <h3>NFT Collections</h3>
            <a
              href="https://babygeist.medium.com/babygeist-nft-collections-and-babyfinance-9aacdc8c3b78"
              target="_blank"
              rel="noreferrer"
              className="learn-more"
            >
              Learn more
            </a>
            <a href="/mint" className="mint-nft-btn" onClick={handleClickNFTs}>
              <button className="buy">Get Yours!</button>
            </a>
            <div className="nft-grid">
              <div>
                <p>
                  <a href="/mint/elementals" onClick={handleElementals}>
                    'Elementals' Collection
                  </a>{" "}
                  out <span>October 24th FREE</span>
                  <br />
                  for BabyBoo Kittens holders.
                </p>
                <a href="/mint/elementals" onClick={handleElementals}>
                  <img alt="" src={elemental} />
                </a>
              </div>
              <div>
                <p>
                  <a href="/mint/ghosty" onClick={handleGhosty}>
                    BabyGeist 'Ghosty' Collection
                  </a>{" "}
                  out <span>October 31st</span>.
                  <br />
                  <br />
                </p>
                <a href="/mint/ghosty" onClick={handleGhosty}>
                  <img alt="" src={gh} />
                </a>
              </div>
            </div>
            <h4 className="nft-staking">
              <a href="/stake" onClick={handleStakeClick}>
                NFT Staking - LIVE NOW
              </a>
            </h4>
            <p>
              Staking NFTs allows guarantees you a portion of future BabyFinance
              tokens’ initial mint.
              <br />
              The percentage you’ll receive is determined by the number of NFTs
              you’re staking, the length of time you have them staked, and the
              weight of the collection.
            </p>
            <h3 className="giveaways">Open Giveaways</h3>
            <div className="giveaway-table">
              {/* <div>
                <h4>Twitter Airdrop</h4>
                <p>(Extended to October 22nd)</p>
                <p>
                  <a
                    href="https://twitter.com/BabyGeistFTM/status/1445874407348391943"
                    target="_blank"
                    rel="noreferrer"
                  >
                    How to Enter
                  </a>
                </p>
              </div> */}
              <div>
                <h4>
                  Artwork / Meme
                  <br />
                  Giveaway
                </h4>
                <p>200m $bGEIST (Approx. $350)</p>
                <p>
                  <a
                    href="https://twitter.com/BabyGeistFTM/status/1449740377884332041"
                    target="_blank"
                    rel="noreferrer"
                  >
                    How to Enter
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="what-is box">
            <div>
              <h3>What is BabyGeist?</h3>
              <p>
                BabyGeist is a reflection token that automatically pays holders
                with $GEIST.
                <br />
                <br />
                Transactions have a 10% fee which is automatically sold and used
                to buy $GEIST for holders, increase liquidity, and pay for
                BabyGeist marketing.
              </p>
              <p>
                <a
                  href="https://babygeist.gitbook.io/docs/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </p>
              <p>
                <a
                  href="https://geist.finance/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Geist
                </a>{" "}
                is a decentralised non-custodial liquidity market protocol where
                users can participate as depositors or borrowers.
              </p>
              <a
                href="https://spookyswap.finance/swap?outputCurrency=0x727465b34ee5c2f4a2b55083a326b3634d2da6b0"
                target="_blank"
                rel="noreferrer"
              >
                <button className="buy">Buy Now</button>
              </a>
              <p className="slogan">
                Buy and hold BabyGeist - earn Geist. It's that simple!
              </p>
            </div>
            <div className="total-dividends">
              <div>
                <p>Total Dividends Distributed</p>
                <p className="tdd">{totalDividendsFormatted} GEIST</p>
              </div>
            </div>
          </div>
          <div className="lower-boxes">
            <div className="tokenomics box">
              <h3>$bGEIST Tokenomics</h3>
              <ul>
                <li>
                  <span>7%</span> of each transaction is redistributed to
                  holders
                </li>
                <li>
                  <span>2%</span> of each transaction will be used for marketing
                </li>
                <li>
                  <span>1%</span> of each transaction is added to a permanently
                  burned liquidity pool
                </li>
              </ul>
            </div>
            <div id="claim" className="box">
              <h3>Claim your $GEIST</h3>
              <div>
                <div className="to-claim">
                  <p>Your $GEIST to Claim</p>
                  <p className="geist">{toClaimFormatted} GEIST</p>
                </div>
                <div className="claim-action">
                  {account ? (
                    <button
                      onClick={handleClaim}
                      disabled={pending || !toClaim || toClaim.lte(0)}
                    >
                      {pending ? "Pending" : "Claim"}
                    </button>
                  ) : (
                    <ConnectButton />
                  )}
                </div>
              </div>
              <p className="total">Your total $GEIST rewards</p>
              <p className="geist">{userTotalDividendsFormatted} GEIST</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
