import * as React from "react";
import {
  babyGeistStateDefault,
  useBabyGeistState,
} from "../Hooks/useBabyGeistState";

export const BabyGeistContext = React.createContext<
  ReturnType<typeof useBabyGeistState>
>({
  state: { ...babyGeistStateDefault },
  claim: () => Promise.reject(),
  closeNotification: () => {},
  closePending: () => {},
  closeSubmittedTx: () => {},
  mintElemental: () => Promise.reject(),
  getMintedElemental: () => Promise.reject(),
  setGetElementalsData: () => {},
  setGetStakingData: () => {},
  unstakeNFT: () => Promise.reject(),
  approveNFT: () => Promise.reject(),
  stakeNFT: () => Promise.reject(),
  getMintedGhosty: () => Promise.reject(),
  mintGhosty: () => Promise.reject(),
  setGetGhostyData: () => {},
  claimGhostyRewards: () => Promise.reject(),
});

export function useBabyGeistContext() {
  return React.useContext(BabyGeistContext);
}
