import * as React from "react";
import styled from "styled-components";
import { pr } from "../AppTheme";
import background from "../Content/background_1.png";
import { ConnectButton } from "../Components/ConnectButton";
import { useBabyGeistContext } from "../Contexts/BabyGeistContext";
import { useWeb3React } from "../Hooks/useWeb3React";
import Collapsible from "react-collapsible";
import { Modal } from "../Components/Modals/Modal";
import { LoadingModal } from "../Components/Modals/LoadingModal";
import { TransactionSubmitted } from "../Components/Modals/TransactionSubmitted";
import { NotifyModal } from "../Components/Modals/NotifyModal";
import { BigNumber } from "ethers";
import { IoIosArrowDown } from "react-icons/io";
import { NFTModal } from "../Components/Modals/NFTModal";
import { ICollectionToken, IStakedNFT } from "../Hooks/useBabyGeistState";

const Container = styled.div`
  min-height: calc(100vh - 6.5rem);
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  div.body {
    padding: ${pr(64)} ${pr(48)} ${pr(128)};
    max-width: 1240px;
    margin: auto;

    div.stake-box {
      background-color: white;
      border: 2px solid ${({ theme }) => theme.palette.secondary.main};
      box-shadow: 0 1px 3px 1px rgb(0 0 0 / 50%);
      padding: ${pr(64)};
      margin: ${pr(64)} ${pr(-64)};

      @media (max-width: 1367px) {
        margin: 0;
      }

      p {
        margin-bottom: 0;
      }

      div.score,
      div.staked,
      div.users {
        h2 {
          font-size: ${pr(32)};
          margin: 0;
        }

        p {
          margin: ${pr(8)} 0 0;

          span {
            font-weight: bold;
            font-size: ${pr(24)};
          }
        }
      }

      div.score {
        padding: ${pr(60)} 0 0;
      }

      div.staked {
        padding: ${pr(60)} 0 0;
      }

      div.users {
        padding: ${pr(60)} 0 0;
      }

      div.Collapsible {
        padding: ${pr(25)} 0 0;

        .no-nfts {
          text-align: center;
        }

        > span {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid black;
          border-bottom: 1px solid black;
          background-color: ${({ theme }) => theme.palette.primary.main};
          color: white;
          padding: ${pr(15)};
          font-size: ${pr(24)};
          margin: 0 ${pr(-15)};
          cursor: pointer;
          user-select: none;

          span {
            background-color: white;
            display: inline-block;
            padding: ${pr(3)} ${pr(6)};
            line-height: 100%;
            border-radius: 5px;
            margin-left: ${pr(15)};
          }

          svg {
            transition: transform 200ms;
          }

          &.is-open svg {
            transform: rotate(180deg);
          }
        }

        > div > div {
          padding: ${pr(20)} 0;
          border-bottom: 1px solid black;

          table {
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;

            th,
            td {
              font-size: ${pr(21)};
              text-align: left;

              :last-of-type {
                text-align: center;
              }
            }

            td {
              padding: 0;
              padding: ${pr(10)} ${pr(5)};

              button {
                background-color: ${({ theme }) => theme.palette.primary.main};
                border: none;
                border-radius: 10px;
                font-size: ${pr(21)};
                padding: ${pr(5)} ${pr(10)};
                cursor: pointer;
                color: white;

                &.claim {
                  width: auto;
                  margin: 0 auto;
                }

                :hover {
                  background-color: ${({ theme }) =>
                    theme.palette.primary.dark};

                  :not(:disabled):active {
                    background-color: ${({ theme }) =>
                      theme.palette.primary.main};
                  }
                }

                &:disabled {
                  cursor: auto;
                  background-color: ${({ theme }) =>
                    theme.palette.secondary.dark};
                  opacity: 0.2;
                }
              }
            }

            th {
              padding: 0 ${pr(5)} ${pr(10)};
            }

            tbody {
              tr {
                cursor: pointer;

                :hover {
                  background-color: #eee;

                  :active {
                    background-color: #ddd;
                  }
                }
              }
            }
          }
        }
      }

      div.title {
        display: flex;
        align-items: center;

        h1 {
          font-size: ${pr(48)};
          margin: 0;
        }

        button {
          background-color: ${({ theme }) => theme.palette.primary.main};
          border: none;
          border-radius: 10px;
          font-size: ${pr(28)};
          padding: ${pr(10)} ${pr(48)};
          cursor: pointer;
          color: white;
          margin-left: auto;

          :hover {
            background-color: ${({ theme }) => theme.palette.primary.dark};

            :active {
              background-color: ${({ theme }) => theme.palette.primary.main};
            }
          }

          &:disabled {
            cursor: auto;
            background-color: ${({ theme }) => theme.palette.secondary.dark};
            opacity: 0.2;
          }
        }
      }

      p {
        font-size: ${pr(21)};

        &:first-of-type {
          margin-top: ${pr(28)};
        }
      }
    }
  }
`;

export const Stake: React.FC = () => {
  const {
    setGetStakingData,
    state,
    closePending: handleClosePending,
    closeSubmittedTx: handleSubmittedTxClose,
    closeNotification: handleNotificationClose,
    unstakeNFT,
    stakeNFT,
    approveNFT,
  } = useBabyGeistContext();
  const {
    stakedNFTs,
    userTokens,
    nftCollections,
    pendingOpen,
    submittedTx,
    notification,
    notificationTitle,
    collectionApprovals,
    score,
    totalScore,
  } = state;
  const { account } = useWeb3React();
  const [pendingStaking, setPendingStaking] = React.useState<{
    [cid: number]: { [tokenId: string]: boolean };
  }>({});
  const [pendingUnstaking, setPendingUnstaking] = React.useState<{
    [id: string]: boolean;
  }>({});
  const [pendingApprove, setPendingApprove] = React.useState<{
    [cid: number]: boolean;
  }>({});
  const [stakedCollapsableOpen, setStakedCollapsableOpen] =
    React.useState(false);
  const [nftOpen, setNFTOpen] = React.useState(false);
  const [openNFTCollectionId, setOpenNFTCollectionId] = React.useState(-1);
  const [openNFTTokenId, setOpenNFTTokenId] = React.useState<BigNumber | null>(
    null
  );
  const [openNFTAction, setOpenNFTAction] = React.useState("");
  const [openNFTId, setOpenNFTId] = React.useState(BigNumber.from(0));

  React.useEffect(() => {
    if (stakedNFTs && stakedNFTs.length > 0) setStakedCollapsableOpen(true);
  }, [stakedNFTs]);

  React.useEffect(() => {
    setGetStakingData(true);

    return () => setGetStakingData(false);
  }, [setGetStakingData]);

  const handleApprove = React.useCallback(
    async (e: React.MouseEvent | null, cid: number) => {
      e?.stopPropagation();

      setPendingApprove((p) => ({ ...p, [cid]: true }));
      if (
        !(await approveNFT(cid, () => {
          setPendingApprove((p) => ({ ...p, [cid]: false }));
        }))
      ) {
        setPendingApprove((p) => ({ ...p, [cid]: false }));
      }
    },
    [approveNFT]
  );

  const handleStake = React.useCallback(
    async (e: React.MouseEvent | null, cid: number, tokenId: BigNumber) => {
      e?.stopPropagation();

      setPendingStaking((p) => {
        const pendingColTokens = p[cid] ?? {};
        pendingColTokens[tokenId.toString()] = true;
        p[cid] = pendingColTokens;
        return { ...p };
      });
      if (
        !(await stakeNFT(cid, tokenId, () => {
          setPendingStaking((p) => {
            const pendingColTokens = p[cid] ?? {};
            pendingColTokens[tokenId.toString()] = false;
            p[cid] = pendingColTokens;
            return { ...p };
          });
        }))
      ) {
        setPendingStaking((p) => {
          const pendingColTokens = p[cid] ?? {};
          pendingColTokens[tokenId.toString()] = false;
          p[cid] = pendingColTokens;
          return { ...p };
        });
      }
    },
    [stakeNFT]
  );

  const handleUnstake = React.useCallback(
    async (e: React.MouseEvent | null, id: BigNumber) => {
      e?.stopPropagation();

      setPendingUnstaking((p) => ({ ...p, [id.toString()]: true }));
      if (
        !(await unstakeNFT(id, () => {
          setPendingUnstaking((p) => ({ ...p, [id.toString()]: false }));
        }))
      ) {
        setPendingUnstaking((p) => ({ ...p, [id.toString()]: false }));
      }
    },
    [unstakeNFT]
  );

  const handleStakedNFTRowClick = React.useCallback((stakedNFT: IStakedNFT) => {
    setNFTOpen(true);
    setOpenNFTCollectionId(stakedNFT.cid);
    setOpenNFTTokenId(stakedNFT.tokenId);
    setOpenNFTAction("Unstake");
    setOpenNFTId(stakedNFT.id);
  }, []);

  const handleNFTRowClick = React.useCallback(
    (token: ICollectionToken) => {
      setNFTOpen(true);
      setOpenNFTCollectionId(token.collectionId);
      setOpenNFTTokenId(token.tokenId);
      if (collectionApprovals && collectionApprovals[token.collectionId]) {
        setOpenNFTAction("Stake");
      } else {
        setOpenNFTAction("Approve");
      }
    },
    [collectionApprovals]
  );

  const handleNFTModalAction = React.useCallback(() => {
    setNFTOpen(false);
    switch (openNFTAction) {
      case "Unstake":
        handleUnstake(null, openNFTId);
        break;
      case "Stake":
        handleStake(null, openNFTCollectionId, openNFTTokenId!);
        break;
      case "Approve":
        handleApprove(null, openNFTCollectionId);
        break;
    }
  }, [
    handleApprove,
    handleStake,
    handleUnstake,
    openNFTAction,
    openNFTCollectionId,
    openNFTId,
    openNFTTokenId,
  ]);

  return (
    <>
      <Modal
        open={pendingOpen}
        onClose={handleClosePending}
        content={(props) => (
          <LoadingModal {...props} text="Pending Transaction" />
        )}
      />
      <Modal
        open={submittedTx !== ""}
        onClose={handleSubmittedTxClose}
        content={(props) => (
          <TransactionSubmitted
            {...props}
            para={notification}
            tx={submittedTx}
          />
        )}
      />
      <Modal
        open={notification !== ""}
        onClose={handleNotificationClose}
        content={(props) => (
          <NotifyModal
            {...props}
            para={notification}
            title={notificationTitle}
          />
        )}
      />
      <Modal
        open={nftOpen}
        onClose={() => setNFTOpen(false)}
        content={(props) => {
          if (!openNFTTokenId) return <></>;
          const nft = userTokens?.find(
            (ut) =>
              ut.collectionId === openNFTCollectionId &&
              ut.tokenId.eq(openNFTTokenId)
          );
          if (!nft) return <></>;

          let actionDisabled = false;

          switch (openNFTAction) {
            case "Stake":
              actionDisabled =
                pendingStaking[openNFTCollectionId] &&
                pendingStaking[openNFTCollectionId][openNFTTokenId!.toString()];
              break;
            case "Unstake":
              actionDisabled = pendingUnstaking[openNFTId.toString()];
              break;
            case "Approve":
              actionDisabled = pendingApprove[openNFTCollectionId];
              break;
          }

          return (
            <NFTModal
              {...props}
              name={nft.name}
              img={nft.image}
              onAction={handleNFTModalAction}
              action={openNFTAction}
              actionDisabled={actionDisabled}
            />
          );
        }}
      />
      <Container>
        <div className="body">
          <div className="stake-box">
            <div className="title">
              <h1>NFT Staking</h1>
              <ConnectButton />
            </div>
            <p>
              Staking NFTs allows guarantees you a portion of future BabyFinance
              tokens’ initial mint. The percentage you’ll receive is determined
              by the number of NFTs you’re staking, the length of time you have
              them staked, and the weight of the collection.
            </p>
            <p>
              While there are no tokens out yet, you can view a 'score' and the
              percentage of the total score you have.
            </p>
            <p>
              When a new token is released, the tokens you receive will be
              vested over a certain period of time and everyone's scores are
              reset.
            </p>
            <div className="score">
              <h2>Your Score</h2>
              <p>
                Score:{" "}
                <span>{score?.toNumber().toLocaleString() ?? "---"}</span>{" "}
                <span className="percent">
                  (
                  {(
                    (score?.mul(100).toNumber() ?? 0) /
                    (totalScore?.toNumber() ?? 1)
                  ).toFixed(4)}
                  %)
                </span>
              </p>
            </div>
            <div className="staked">
              <h2>Staked NFTs</h2>
              <p>
                You have{" "}
                <span>
                  {account && stakedNFTs
                    ? stakedNFTs.length > 0
                      ? stakedNFTs.length
                      : "no"
                    : "-"}
                </span>{" "}
                staked NFT(s)
              </p>
              <Collapsible
                trigger={
                  <>
                    Your Staked NFTs
                    <IoIosArrowDown />
                  </>
                }
                transitionTime={200}
                open={stakedCollapsableOpen}
                handleTriggerClick={() => setStakedCollapsableOpen((o) => !o)}
              >
                <table>
                  <thead>
                    <tr>
                      <th>Name (ID)</th>
                      <th>Collection</th>
                      <th>Multiplier</th>
                      <th>Unstake</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stakedNFTs?.map((token) => {
                      const userToken = userTokens?.find(
                        (ut) =>
                          ut.collectionId === token.cid &&
                          ut.tokenId.eq(token.tokenId)
                      );
                      const collection = nftCollections
                        ? nftCollections[token.cid]
                        : undefined;
                      return (
                        <tr
                          key={`staked-token-${token.id}`}
                          onClick={() => handleStakedNFTRowClick(token)}
                        >
                          <td>
                            {userToken?.name ?? "---"} (#
                            {userToken?.tokenId.toString() ?? "-"})
                          </td>
                          <td>{collection?.name ?? "---"}</td>
                          <td>
                            {collection
                              ? `${collection.multiplier.toString()}x`
                              : "---"}
                          </td>
                          <td>
                            <button
                              onClick={(e) => handleUnstake(e, token.id)}
                              disabled={pendingUnstaking[token.id.toString()]}
                            >
                              Unstake
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {!stakedNFTs && (
                  <p className="no-nfts">
                    {account ? "Pending" : "Not Connected"}
                  </p>
                )}
                {stakedNFTs?.length === 0 && (
                  <p className="no-nfts">You have no staked NFTs</p>
                )}
              </Collapsible>
            </div>
            <div className="users">
              <h2>Your NFTs</h2>
              {nftCollections?.map((collection, cid) => (
                <Collapsible
                  key={`your-nfts-${collection.address}`}
                  trigger={
                    <>
                      <div>
                        {collection.name}{" "}
                        <span>{collection.multiplier.toString()}x</span>
                      </div>
                      <IoIosArrowDown />
                    </>
                  }
                  transitionTime={200}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>Name (ID)</th>
                        <th>Stake</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userTokens
                        ?.filter(
                          (ut) =>
                            ut.collectionId === cid &&
                            !stakedNFTs?.find(
                              (s) => s.cid === cid && s.tokenId.eq(ut.tokenId)
                            )
                        )
                        ?.map((token) => {
                          return (
                            <tr
                              key={`your-nfts-${collection.address}-${token.tokenId}`}
                              onClick={() => handleNFTRowClick(token)}
                            >
                              <td>
                                {token?.name ?? "---"} (#
                                {token?.tokenId.toString() ?? "-"})
                              </td>
                              <td>
                                {collectionApprovals &&
                                collectionApprovals[cid] ? (
                                  <button
                                    onClick={(e) =>
                                      handleStake(
                                        e,
                                        token.collectionId,
                                        token.tokenId
                                      )
                                    }
                                    disabled={
                                      pendingStaking[cid] &&
                                      pendingStaking[cid][
                                        token.tokenId.toString()
                                      ]
                                    }
                                  >
                                    Stake
                                  </button>
                                ) : (
                                  <button
                                    onClick={(e) =>
                                      handleApprove(e, token.collectionId)
                                    }
                                    disabled={pendingApprove[cid]}
                                  >
                                    Approve
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {!userTokens && (
                    <p className="no-nfts">
                      {account ? "Pending" : "Not Connected"}
                    </p>
                  )}
                  {userTokens?.filter(
                    (ut) =>
                      ut.collectionId === cid &&
                      !stakedNFTs?.find(
                        (s) => s.cid === cid && s.tokenId.eq(ut.tokenId)
                      )
                  )?.length === 0 && (
                    <p className="no-nfts">
                      You own no NFTs belonging to this collection
                    </p>
                  )}
                </Collapsible>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
