import * as React from "react";
import Countdown from "react-countdown";
import styled from "styled-components";
import { pr } from "../AppTheme";
import { ConnectButton } from "../Components/ConnectButton";
import elementals_background from "../Content/elementals_background.png";
import { useBabyGeistContext } from "../Contexts/BabyGeistContext";
import { useWeb3React } from "../Hooks/useWeb3React";
import elemental from "../Content/elemental.png";
import { Modal } from "../Components/Modals/Modal";
import { LoadingModal } from "../Components/Modals/LoadingModal";
import { TransactionSubmitted } from "../Components/Modals/TransactionSubmitted";
import { NotifyModal } from "../Components/Modals/NotifyModal";

const Container = styled.div<{ enter: boolean }>`
  background-image: url(${elementals_background});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  min-height: calc(100vh - 6.5rem);
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border: none;
    border-radius: 10px;
    font-size: ${pr(28)};
    padding: ${pr(10)} ${pr(48)};
    cursor: pointer;
    color: white;

    :hover {
      background-color: ${({ theme }) => theme.palette.primary.dark};

      :not(:disabled):active {
        background-color: ${({ theme }) => theme.palette.primary.main};
      }
    }

    &:disabled {
      cursor: auto;
      background-color: ${({ theme }) => theme.palette.secondary.dark};
      opacity: 0.2;
    }
  }

  .mint-box {
    background-color: white;
    border-radius: ${pr(40)};
    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 50%);
    padding: ${pr(60)} ${pr(40)};
    transition: opacity 1s ease-in-out;
    opacity: ${({ enter }) => (enter ? 1 : 0)};
    margin: ${pr(20)};

    button {
      margin: ${pr(24)} 0;
      font-size: ${pr(32)};
      width: 100%;

      span {
        color: white;
      }
    }

    p {
      text-align: center;
      margin: 0;
      font-size: ${pr(21)};

      &.minted-name {
        font-size: ${pr(28)};
        margin-bottom: ${pr(14)};
      }
    }

    div.preview-box {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        opacity: 0;
        transition: opacity 1s ease-out;
        width: ${pr(375)};
        border: 5px solid ${({ theme }) => theme.palette.secondary.main};

        &.minted-elemental {
          position: absolute;
          transition: opacity 5s ease-in;
        }

        &.showing {
          opacity: 1;
        }
      }

      div.loader {
        position: absolute;
        transform: scale(2);

        .lds-default {
          display: inline-block;
          position: relative;
          width: 80px;
          height: 80px;
        }
        .lds-default div {
          position: absolute;
          width: 6px;
          height: 6px;
          background: ${({ theme }) => theme.palette.secondary.main};
          border-radius: 50%;
          animation: lds-default 1.2s linear infinite;
        }
        .lds-default div:nth-child(1) {
          animation-delay: 0s;
          top: 37px;
          left: 66px;
        }
        .lds-default div:nth-child(2) {
          animation-delay: -0.1s;
          top: 22px;
          left: 62px;
        }
        .lds-default div:nth-child(3) {
          animation-delay: -0.2s;
          top: 11px;
          left: 52px;
        }
        .lds-default div:nth-child(4) {
          animation-delay: -0.3s;
          top: 7px;
          left: 37px;
        }
        .lds-default div:nth-child(5) {
          animation-delay: -0.4s;
          top: 11px;
          left: 22px;
        }
        .lds-default div:nth-child(6) {
          animation-delay: -0.5s;
          top: 22px;
          left: 11px;
        }
        .lds-default div:nth-child(7) {
          animation-delay: -0.6s;
          top: 37px;
          left: 7px;
        }
        .lds-default div:nth-child(8) {
          animation-delay: -0.7s;
          top: 52px;
          left: 11px;
        }
        .lds-default div:nth-child(9) {
          animation-delay: -0.8s;
          top: 62px;
          left: 22px;
        }
        .lds-default div:nth-child(10) {
          animation-delay: -0.9s;
          top: 66px;
          left: 37px;
        }
        .lds-default div:nth-child(11) {
          animation-delay: -1s;
          top: 62px;
          left: 52px;
        }
        .lds-default div:nth-child(12) {
          animation-delay: -1.1s;
          top: 52px;
          left: 62px;
        }
        @keyframes lds-default {
          0%,
          20%,
          80%,
          100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.5);
          }
        }
      }
    }
  }
`;

export const MintElementals: React.FC = () => {
  const {
    state,
    mintElemental,
    getMintedElemental,
    closeNotification: handleNotificationClose,
    closePending: handleClosePending,
    closeSubmittedTx: handleSubmittedTxClose,
    setGetElementalsData,
  } = useBabyGeistContext();
  const {
    elementalsClaimed,
    elementalsRemaining,
    elementalsStartTime,
    elementalsWhitelisted,
    elementalsMaxSupply,
    elementalsMinted,
    pendingOpen,
    submittedTx,
    notification,
    notificationTitle,
  } = state;
  const { account } = useWeb3React();
  const [started, setStarted] = React.useState(false);
  const [mintedImg, setMintedImg] = React.useState("");
  const [showPreview, setShowPreview] = React.useState(true);
  const [pending, setPending] = React.useState(false);
  const [mintedName, setMintedName] = React.useState("");
  const [enter, setEnter] = React.useState(false);

  React.useEffect(() => {
    setGetElementalsData(true);

    return () => setGetElementalsData(false);
  }, [setGetElementalsData]);

  React.useEffect(() => {
    requestAnimationFrame(() => setEnter(true));
  }, []);

  React.useEffect(() => {
    if (elementalsStartTime && Date.now() >= elementalsStartTime) {
      if (!started) {
        setStarted(true);
      }
    } else if (started) {
      setStarted(false);
    }
  }, [elementalsStartTime, started]);

  const handleComplete = React.useCallback(() => {
    setStarted(true);
  }, []);

  const handleMint = React.useCallback(async () => {
    setPending(true);
    if (
      await mintElemental(() => {
        setPending(false);
        setShowPreview(true);
      })
    ) {
      setShowPreview(false);
    } else {
      setPending(false);
    }
  }, [mintElemental]);

  React.useEffect(() => {
    if (elementalsMinted) {
      setPending(false);
      getMintedElemental().then((meta) => {
        setMintedImg(meta["image"]);
        setMintedName(meta["name"]);
      });
    }
  }, [elementalsMinted, getMintedElemental]);

  function buttonText() {
    if (!elementalsStartTime || pending) return "Pending";
    if (!started) {
      return (
        <Countdown
          date={elementalsStartTime}
          daysInHours
          onComplete={handleComplete}
        />
      );
    }
    if (elementalsRemaining === 0) {
      return "Sold Out!";
    }
    if (!elementalsWhitelisted) {
      return "Not Whitelisted";
    }
    if (elementalsClaimed) {
      return "Already Claimed";
    }

    return "Mint";
  }

  function renderButton() {
    if (account) {
      return (
        <button
          disabled={
            !elementalsWhitelisted ||
            elementalsClaimed ||
            !started ||
            pending ||
            elementalsRemaining === 0
          }
          onClick={handleMint}
        >
          {buttonText()}
        </button>
      );
    }

    return <ConnectButton />;
  }

  return (
    <>
      <Modal
        open={pendingOpen}
        onClose={handleClosePending}
        content={(props) => (
          <LoadingModal {...props} text="Pending Transaction" />
        )}
      />
      <Modal
        open={submittedTx !== ""}
        onClose={handleSubmittedTxClose}
        content={(props) => (
          <TransactionSubmitted
            {...props}
            para={notification}
            tx={submittedTx}
          />
        )}
      />
      <Modal
        open={notification !== ""}
        onClose={handleNotificationClose}
        content={(props) => (
          <NotifyModal
            {...props}
            para={notification}
            title={notificationTitle}
          />
        )}
      />
      <Container enter={enter}>
        <div className="mint-box">
          {mintedName && <p className="minted-name">{mintedName}</p>}
          <div className="preview-box">
            <img
              className={showPreview ? "showing" : ""}
              alt=""
              src={elemental}
            />

            {!showPreview && (
              <div className="loader">
                <div className="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}

            <img
              className={`minted-elemental${mintedImg ? " showing" : ""}`}
              alt=""
              src={mintedImg}
            />
          </div>
          {renderButton()}
          <p>
            {elementalsRemaining ?? "---"}/{elementalsMaxSupply ?? "---"}{" "}
            Remaining
          </p>
        </div>
      </Container>
    </>
  );
};
