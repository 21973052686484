import * as React from "react";
import styled from "styled-components";
import { pr } from "../../AppTheme";

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  button {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 1000px;
    font-size: ${pr(24)};
    font-weight: 300;
    padding: ${pr(6)} ${pr(48)};
    color: white;
    cursor: pointer;
    border: none;

    :hover {
      background-color: ${({ theme }) => theme.palette.primary.dark};

      :active {
        background-color: ${({ theme }) => theme.palette.primary.main};
      }
    }

    :disabled {
      cursor: auto;
      background-color: ${({ theme }) => theme.palette.secondary.dark};
      opacity: 0.2;
    }
  }

  div.modal {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    padding: ${pr(16)} ${pr(32)};
    border-radius: ${pr(5)};
    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 50%);
  }
`;

export interface IContentProps {
  onClose: () => void;
}

interface IProps {
  open?: boolean;
  onClose: () => void;
  content: (props: IContentProps) => React.ReactChild;
}

export const Modal: React.FC<IProps> = ({
  open,
  onClose: handleClose,
  content,
}) => {
  const canClose = React.useRef(false);

  const handleMouseDown = React.useCallback(() => {
    canClose.current = true;
  }, []);

  const handleMouseUp = React.useCallback(() => {
    if (canClose.current) {
      handleClose();
    }
    canClose.current = false;
  }, [handleClose]);

  const handleClick = React.useCallback(() => {
    if (canClose.current) {
      handleClose();
    }
  }, [handleClose]);

  if (!open) return null;

  return (
    <Container
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
    >
      <div
        className="modal"
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {content({ onClose: handleClose })}
      </div>
    </Container>
  );
};
