import * as React from "react";
import { IContentProps } from "./Modal";
import styled from "styled-components";
import { pr } from "../../AppTheme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin: 0 ${pr(5)};
    min-width: ${pr(172)};
  }

  h3 {
    color: white;
    text-align: center;
    font-size: ${pr(28)};
  }

  img {
    width: ${pr(375)};
  }

  div.btn {
    padding: ${pr(18)} 0;
  }
`;

export const NFTModal: React.FC<
  IContentProps & {
    name: string;
    img: string;
    action: string;
    onAction: () => void;
    actionDisabled: boolean;
  }
> = ({
  onClose: handleClose,
  name,
  img,
  action,
  onAction: handleAction,
  actionDisabled,
}) => {
  return (
    <Container>
      <div className="nft">
        <h3>{name}</h3>
        <img alt={name} src={img} />
      </div>
      <div className="btn">
        <button onClick={handleClose}>Close</button>
        <button onClick={handleAction} disabled={actionDisabled}>
          {action}
        </button>
      </div>
    </Container>
  );
};
