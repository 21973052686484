import * as React from "react";
import {
  useDividendContract,
  useTokenContract,
  useElementalsContract,
  useStakingContract,
  getNFTContract,
  useGhostyContract,
} from "./useContract";
import { BigNumber } from "ethers";
import { rpcUrl } from "../constants";
import { useWeb3React } from "./useWeb3React";
import { JsonRpcProvider } from "@ethersproject/providers";
import { TypedListener } from "../typechain/common";

export interface IStakedNFT {
  id: BigNumber;
  cid: number;
  tokenId: BigNumber;
}

export interface ICollectionToken {
  tokenId: BigNumber;
  name: string;
  image: string;
  collectionId: number;
}

interface ICollection {
  name: string;
  address: string;
  multiplier: BigNumber;
}

export interface IBabyGeistState {
  gotAccount: boolean;
  totalDividends?: BigNumber;
  toClaim?: BigNumber;
  notification: string;
  notificationTitle: string;
  pendingOpen: boolean;
  submittedTx: string;
  userTotalDividends?: BigNumber;
  dividendTrackerAddress?: string;
  successfulTxs: string[];
  elementalsWhitelisted?: boolean;
  elementalsClaimed?: boolean;
  elementalsStartTime?: number;
  elementalsRemaining?: number;
  elementalsMaxSupply?: number;
  elementalsMinted?: BigNumber;
  getElementalsData: boolean;
  getStakingData: boolean;
  stakedNFTs?: IStakedNFT[];
  nftCollections?: ICollection[];
  userTokens?: ICollectionToken[];
  score?: BigNumber;
  totalScore?: BigNumber;
  collectionApprovals?: boolean[];
  ghostyStartTime?: number;
  ghostyRemaining?: number;
  ghostyMaxSupply?: number;
  getGhostyData: boolean;
  ghostyMinted?: BigNumber;
  ghostyNextMintPrice?: BigNumber;
  ghostyPendingRewards?: BigNumber;
}

export const babyGeistStateDefault: IBabyGeistState = {
  gotAccount: false,
  notification: "",
  notificationTitle: "",
  pendingOpen: false,
  submittedTx: "",
  successfulTxs: [],
  getElementalsData: false,
  getStakingData: false,
  getGhostyData: false,
};

// add 10%
function calculateGasMargin(value: BigNumber): BigNumber {
  return value
    .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
    .div(BigNumber.from(10000));
}

function normaliseNFTURI(uri: string) {
  if (uri.startsWith("ipfs://")) {
    const parts = uri.split("://");
    return `https://ipfs.io/ipfs/${parts[1]}`;
  }
  return uri;
}

export function useBabyGeistState() {
  const [state, _setState] = React.useReducer<
    (s: IBabyGeistState, n: Partial<IBabyGeistState>) => IBabyGeistState
  >((s, n) => ({ ...s, ...n }), { ...babyGeistStateDefault });
  const _getState = React.useRef(state);
  const getState = React.useCallback(() => _getState.current, []);
  const setState = React.useCallback((newState: Partial<IBabyGeistState>) => {
    _setState(newState);
    _getState.current = { ..._getState.current, ...newState };
  }, []);
  const { library, account } = useWeb3React();

  React.useEffect(() => {
    setState({ gotAccount: account !== undefined });
  }, [account, setState]);

  const fallbackProvider = React.useMemo(() => new JsonRpcProvider(rpcUrl), []);

  const signer = React.useMemo(() => library?.getSigner(), [library]);

  const tokenContract = useTokenContract(signer ?? fallbackProvider);
  const dividendTrackerContract = useDividendContract(
    state.dividendTrackerAddress,
    signer ?? fallbackProvider
  );

  const elementalsContract = useElementalsContract(signer ?? fallbackProvider);

  const ghostyContract = useGhostyContract(signer ?? fallbackProvider);

  const stakingContract = useStakingContract(signer ?? fallbackProvider);

  const sendDividendsFilter = React.useMemo(
    () => tokenContract.filters.SendDividends(),
    [tokenContract]
  );

  const claimFilter = React.useMemo(
    () =>
      account ? dividendTrackerContract?.filters.Claim(account) : undefined,
    [account, dividendTrackerContract]
  );

  React.useEffect(() => {
    tokenContract
      ?.getTotalDividendsDistributed()
      .then((totalDividends) => setState({ totalDividends }));

    tokenContract
      ?.dividendTracker()
      .then((dividendTrackerAddress) => setState({ dividendTrackerAddress }));
  }, [setState, tokenContract]);

  React.useEffect(() => {
    if (account) {
      tokenContract
        .getAccountDividendsInfo(account)
        .then(([, , , toClaim, userTotalDividends]) =>
          setState({ toClaim, userTotalDividends })
        );
    } else {
      setState({ toClaim: undefined, userTotalDividends: undefined });
    }
  }, [account, setState, tokenContract]);

  React.useEffect(() => {
    const handler: TypedListener<
      [BigNumber, BigNumber],
      {
        tokensSwapped: BigNumber;
        amount: BigNumber;
      }
    > = (_, amount) => {
      setState({
        totalDividends: (state.totalDividends ?? BigNumber.from(0)).add(amount),
      });
    };

    tokenContract.on(sendDividendsFilter, handler);

    return () => {
      tokenContract.off(sendDividendsFilter, handler);
    };
  }, [sendDividendsFilter, setState, state.totalDividends, tokenContract]);

  React.useEffect(() => {
    if (account) {
      const handler = () => {
        tokenContract.withdrawableDividendOf(account).then((toClaim) => {
          setState({ toClaim });
        });
      };

      tokenContract.on(sendDividendsFilter, handler);

      if (claimFilter) {
        dividendTrackerContract?.on(claimFilter, handler);
      }

      return () => {
        tokenContract.off(sendDividendsFilter, handler);

        if (claimFilter) {
          dividendTrackerContract?.off(claimFilter, handler);
        }
      };
    }
  }, [
    account,
    claimFilter,
    dividendTrackerContract,
    sendDividendsFilter,
    setState,
    tokenContract,
  ]);

  const handleTransactionError = React.useCallback(
    (e: any) => {
      console.error("Failed", e);
      setState({
        notificationTitle: "Transaction Failed",
      });
      if (e.code === 4001) {
        setState({
          notification: "Transaction Rejected",
        });
      } else {
        if (e?.data?.message) {
          setState({
            notification: e.data.message.replace("execution reverted: ", ""),
          });
        } else {
          setState({
            notification: "Unknown Error",
          });
        }
      }
    },
    [setState]
  );

  const claim = React.useCallback(async () => {
    if (account) {
      setState({
        pendingOpen: true,
      });
      try {
        const estimatedGas = await tokenContract.estimateGas.claim();

        const receipt = await tokenContract.claim({
          gasLimit: calculateGasMargin(estimatedGas),
        });

        setState({
          submittedTx: receipt.hash,
        });

        receipt.wait().then(() => {
          setState({
            toClaim: BigNumber.from(0),
            successfulTxs: [...state.successfulTxs, receipt.hash],
          });
        });
      } catch (e) {
        handleTransactionError(e);
      } finally {
        setState({
          pendingOpen: false,
        });
      }
    }
  }, [
    account,
    handleTransactionError,
    setState,
    state.successfulTxs,
    tokenContract,
  ]);

  const closeNotification = React.useCallback(() => {
    setState({ notification: "" });
  }, [setState]);

  const closePending = React.useCallback(() => {
    setState({ pendingOpen: false });
  }, [setState]);

  const closeSubmittedTx = React.useCallback(() => {
    setState({ submittedTx: "" });
  }, [setState]);

  // Elementals

  const getElementalsData = React.useCallback(() => {
    elementalsContract
      .startTime()
      .then((startTime) =>
        setState({ elementalsStartTime: startTime.mul(1000).toNumber() })
      );
    Promise.all([
      elementalsContract.totalSupply(),
      elementalsContract.maxSupply(),
    ]).then(([total, max]) =>
      setState({
        elementalsRemaining: max.sub(total).toNumber(),
        elementalsMaxSupply: max.toNumber(),
      })
    );
    if (account) {
      elementalsContract
        .whitelist(account)
        .then((elementalsWhitelisted) => setState({ elementalsWhitelisted }));
      elementalsContract.claimed(account).then((elementalsClaimed) => {
        setState({
          elementalsClaimed: elementalsClaimed || state.elementalsClaimed,
        });
      });
    }
  }, [account, elementalsContract, setState, state.elementalsClaimed]);

  const setGetElementalsData = React.useCallback(
    (getData: boolean) => {
      setState({ getElementalsData: getData });
    },
    [setState]
  );

  React.useEffect(() => {
    let interval = -1;
    if (state.getElementalsData) {
      getElementalsData();

      interval = window.setInterval(getElementalsData, 1000);
    }

    return () => {
      if (interval >= 0) clearInterval(interval);
    };
  }, [getElementalsData, state.getElementalsData]);

  const mintElemental = React.useCallback(
    async (onFail: () => any) => {
      if (account) {
        setState({
          pendingOpen: true,
        });
        try {
          const estimatedGas = await elementalsContract.estimateGas.mint();

          const receipt = await elementalsContract.mint({
            gasLimit: calculateGasMargin(estimatedGas),
          });

          setState({
            submittedTx: receipt.hash,
          });

          receipt
            .wait()
            .then((r) => {
              const transferFilter = elementalsContract.filters.Transfer();

              const mintLog = r.logs.find(
                (r) => r.topics[0] === transferFilter.topics![0]
              );

              setState({
                successfulTxs: [...state.successfulTxs, receipt.hash],
                elementalsClaimed: true,
                elementalsRemaining: (state.elementalsRemaining ?? 0) - 1,
                elementalsMinted: mintLog
                  ? BigNumber.from(mintLog?.topics[3])
                  : undefined,
              });
            })
            .catch((e) => {
              handleTransactionError(e);
              onFail();
            });

          return true;
        } catch (e) {
          handleTransactionError(e);
        } finally {
          setState({
            pendingOpen: false,
          });
        }
      }

      return false;
    },
    [
      account,
      elementalsContract,
      handleTransactionError,
      setState,
      state.elementalsRemaining,
      state.successfulTxs,
    ]
  );

  const getMintedElemental = React.useCallback(() => {
    return new Promise<{ [key: string]: any }>(async (resolve) => {
      if (state.elementalsMinted) {
        const uri = await elementalsContract.tokenURI(state.elementalsMinted);
        const interval = setInterval(async () => {
          try {
            const result = await fetch(uri);
            if (result.ok) {
              clearInterval(interval);
              resolve(await result.json());
            }
          } catch (e) {
            console.error(e);
          }
        }, 1000);
        setState({});
      }
    });
  }, [elementalsContract, setState, state.elementalsMinted]);

  // Ghosty

  const getGhostyData = React.useCallback(() => {
    Promise.all([
      ghostyContract.totalSupply(),
      ghostyContract.maxSupply(),
      ghostyContract.startTime(),
    ]).then(([total, max, startTime]) =>
      setState({
        ghostyRemaining: max.sub(total).toNumber(),
        ghostyMaxSupply: max.toNumber(),
        ghostyStartTime: startTime.mul(1000).toNumber(),
      })
    );
    if (account) {
      ghostyContract
        .pendingRewards(account)
        .then((ghostyPendingRewards) => setState({ ghostyPendingRewards }));
    }
  }, [account, ghostyContract, setState]);

  const getGhostyPriceData = React.useCallback(() => {
    ghostyContract
      .nextMintPrice()
      .then((ghostyNextMintPrice) => setState({ ghostyNextMintPrice }));
  }, [ghostyContract, setState]);

  const setGetGhostyData = React.useCallback(
    (getData: boolean) => {
      setState({ getGhostyData: getData });
    },
    [setState]
  );

  React.useEffect(() => {
    let interval = -1;
    let quickInterval = -1;
    if (state.getGhostyData) {
      getGhostyData();
      getGhostyPriceData();

      interval = window.setInterval(getGhostyData, 1000);
      quickInterval = window.setInterval(getGhostyPriceData, 200);
    }

    return () => {
      if (interval >= 0) clearInterval(interval);
      if (quickInterval >= 0) clearInterval(quickInterval);
    };
  }, [getGhostyData, getGhostyPriceData, state.getGhostyData]);

  const claimGhostyRewards = React.useCallback(
    async (onComplete: () => any) => {
      if (account) {
        setState({
          pendingOpen: true,
        });
        try {
          const estimatedGas = await ghostyContract.estimateGas.claim();

          const receipt = await ghostyContract.claim({
            gasLimit: calculateGasMargin(estimatedGas),
          });

          setState({
            submittedTx: receipt.hash,
          });

          receipt
            .wait()
            .then(() => {
              setState({
                successfulTxs: [...state.successfulTxs, receipt.hash],
                ghostyPendingRewards: BigNumber.from(0),
              });
              onComplete();
            })
            .catch((e) => {
              handleTransactionError(e);
            });

          return true;
        } catch (e) {
          handleTransactionError(e);
          onComplete();
        } finally {
          setState({
            pendingOpen: false,
          });
        }
      }

      return false;
    },
    [
      account,
      ghostyContract,
      handleTransactionError,
      setState,
      state.successfulTxs,
    ]
  );

  const mintGhosty = React.useCallback(
    async (onFail: () => any) => {
      if (account) {
        setState({
          pendingOpen: true,
        });
        try {
          const mintPrice = state.ghostyNextMintPrice;

          const estimatedGas = await ghostyContract.estimateGas.mint({
            value: mintPrice,
          });

          const receipt = await ghostyContract.mint({
            gasLimit: calculateGasMargin(estimatedGas),
            value: mintPrice,
          });

          setState({
            submittedTx: receipt.hash,
          });

          receipt
            .wait()
            .then((r) => {
              const transferFilter = ghostyContract.filters.Transfer();

              const mintLog = r.logs.find(
                (r) =>
                  r.address === ghostyContract.address &&
                  r.topics[0] === transferFilter.topics![0]
              );

              setState({
                successfulTxs: [...state.successfulTxs, receipt.hash],
                ghostyRemaining: (getState().ghostyRemaining ?? 0) - 1,
                ghostyMinted: mintLog
                  ? BigNumber.from(mintLog?.topics[3])
                  : undefined,
              });
            })
            .catch((e) => {
              handleTransactionError(e);
              onFail();
            });

          return true;
        } catch (e) {
          handleTransactionError(e);
        } finally {
          setState({
            pendingOpen: false,
          });
        }
      }

      return false;
    },
    [
      account,
      getState,
      ghostyContract,
      handleTransactionError,
      setState,
      state.ghostyNextMintPrice,
      state.successfulTxs,
    ]
  );

  const getMintedGhosty = React.useCallback(() => {
    return new Promise<{ [key: string]: any }>(async (resolve) => {
      if (state.ghostyMinted) {
        const uri = await ghostyContract.tokenURI(state.ghostyMinted);
        const interval = setInterval(async () => {
          try {
            const result = await fetch(uri);
            if (result.ok) {
              clearInterval(interval);
              resolve(await result.json());
            }
          } catch (e) {
            console.error(e);
          }
        }, 1000);
        setState({});
      }
    });
  }, [ghostyContract, setState, state.ghostyMinted]);

  // Staking

  React.useEffect(() => {
    if (account) {
      const stakeFilter = stakingContract.filters.Stake(account);
      const unstakeFilter = stakingContract.filters.Unstake(account);

      const stakeHandler: TypedListener<
        [string, BigNumber, BigNumber, BigNumber],
        {
          user: string;
          cid: BigNumber;
          tokenId: BigNumber;
          stakedTokenId: BigNumber;
        }
      > = (_user, cid, tokenId, stakedTokenId) => {
        const freshState = getState();
        const newStakedNFTs = freshState.stakedNFTs
          ? [...freshState.stakedNFTs]
          : [];
        if (!newStakedNFTs.find((s) => s.id.eq(stakedTokenId))) {
          newStakedNFTs.push({
            cid: cid.toNumber(),
            id: stakedTokenId,
            tokenId,
          });
          setState({
            stakedNFTs: newStakedNFTs,
          });
        }
      };

      const unstakeHandler: TypedListener<
        [string, BigNumber],
        {
          user: string;
          stakeId: BigNumber;
        }
      > = (_user, stakeId) => {
        const freshState = getState();
        const newStakedNFTs = freshState.stakedNFTs
          ? [...freshState.stakedNFTs]
          : [];
        const indexToRemove = newStakedNFTs.findIndex((s) => s.id.eq(stakeId));
        if (indexToRemove >= 0) {
          newStakedNFTs.splice(indexToRemove, 1);
          setState({
            stakedNFTs: newStakedNFTs,
          });
        }
      };

      stakingContract.on(stakeFilter, stakeHandler);
      stakingContract.on(unstakeFilter, unstakeHandler);

      return () => {
        stakingContract.off(stakeFilter, stakeHandler);
        stakingContract.off(unstakeFilter, unstakeHandler);
      };
    }
  }, [account, getState, setState, stakingContract]);

  React.useEffect(() => {
    if (account && state.nftCollections) {
      const toUnload: (() => void)[] = [];
      for (let i = 0; i < state.nftCollections.length; i++) {
        const collection = state.nftCollections[i];
        const collectionContract = getNFTContract(
          collection.address,
          signer ?? fallbackProvider
        );
        const fromFilter = collectionContract.filters.Transfer(account);
        const toFilter = collectionContract.filters.Transfer(null, account);
        const approveFilter = collectionContract.filters.ApprovalForAll(
          account,
          stakingContract.address
        );

        const fromHandler: TypedListener<
          [string, string, BigNumber],
          {
            from: string;
            to: string;
            tokenId: BigNumber;
          }
        > = (_from, _to, tokenId) => {
          const freshState = getState();
          const newUserTokens = freshState.userTokens
            ? [...freshState.userTokens]
            : [];
          const indexToDelete = newUserTokens.findIndex(
            (ut) => ut.collectionId === i && ut.tokenId.eq(tokenId)
          );
          if (indexToDelete >= 0) {
            newUserTokens.splice(indexToDelete, 1);
            setState({
              userTokens: newUserTokens,
            });
          }
        };

        const toHandler: TypedListener<
          [string, string, BigNumber],
          {
            from: string;
            to: string;
            tokenId: BigNumber;
          }
        > = async (_from, _to, tokenId) => {
          const freshState = getState();
          const newUserTokens = freshState.userTokens
            ? [...freshState.userTokens]
            : [];
          if (
            !newUserTokens.find(
              (ut) => ut.collectionId === i && ut.tokenId.eq(tokenId)
            )
          ) {
            const tokenURI = await collectionContract.tokenURI(tokenId);
            let tokenJson = {
              name: "Error",
              image: "",
            };
            try {
              const response = await fetch(normaliseNFTURI(tokenURI));
              if (response.ok) {
                tokenJson = await response.json();
              } else {
                console.error(
                  `Fetching ${tokenURI} failed - ${response.status}: ${response.statusText}`
                );
              }
            } catch (e) {
              console.error(`Fetching ${tokenURI} failed -`, e);
            }

            newUserTokens.push({
              collectionId: i,
              image: tokenJson.image,
              name: tokenJson.name,
              tokenId,
            });
            setState({
              userTokens: newUserTokens,
            });
          }
        };

        const approveHandler: TypedListener<
          [string, string, boolean],
          {
            owner: string;
            operator: string;
            approved: boolean;
          }
        > = (_owner, _operator, approved) => {
          const freshState = getState();
          if (
            approved &&
            freshState.collectionApprovals &&
            !freshState.collectionApprovals[i]
          ) {
            const collectionApprovals = [...freshState.collectionApprovals];
            collectionApprovals[i] = true;
            setState({
              collectionApprovals,
            });
          }
        };

        collectionContract.on(fromFilter, fromHandler);
        toUnload.push(() => collectionContract.off(fromFilter, fromHandler));

        collectionContract.on(toFilter, toHandler);
        toUnload.push(() => collectionContract.off(toFilter, toHandler));

        collectionContract.on(approveFilter, approveHandler);
        toUnload.push(() =>
          collectionContract.off(approveFilter, approveHandler)
        );
      }

      return () => toUnload.forEach((u) => u());
    }
  }, [
    state.nftCollections,
    account,
    getState,
    setState,
    stakingContract.address,
    signer,
    fallbackProvider,
  ]);

  const getStakingData = React.useCallback(async () => {
    try {
      const colLen = await stakingContract.collectionLength();
      const collections: ICollection[] = [];
      for (let i = BigNumber.from(0); i.lt(colLen); i = i.add(1)) {
        const collection = await stakingContract.collectionInfo(i);
        const collectionContract = getNFTContract(
          collection.collection,
          signer ?? fallbackProvider
        );
        collections[i.toNumber()] = {
          address: collection.collection,
          multiplier: collection.currentMultiplier,
          name: await collectionContract.name(),
        };
        const freshState = getState();
        const settingCollections = [];
        for (
          let j = 0;
          j <
          Math.max(freshState.nftCollections?.length ?? 0, collections.length);
          j++
        ) {
          if (j < collections.length) {
            settingCollections[j] = collections[j];
          } else if (
            freshState.nftCollections &&
            j < freshState.nftCollections.length
          ) {
            settingCollections[j] = freshState.nftCollections[j];
          }
        }
        setState({
          nftCollections: settingCollections,
        });
      }
      const freshState = getState();
      const settingCollections = [];
      for (
        let j = 0;
        j <
        Math.max(freshState.nftCollections?.length ?? 0, collections.length);
        j++
      ) {
        if (j < collections.length) {
          settingCollections[j] = collections[j];
        } else if (
          freshState.nftCollections &&
          j < freshState.nftCollections.length
        ) {
          settingCollections[j] = freshState.nftCollections[j];
        }
      }
      setState({
        nftCollections: settingCollections,
      });

      if (account) {
        const stakedAmount = await stakingContract.userTokenStakes(account);
        const userStakedTokens: IStakedNFT[] = [];
        const userTokens: ICollectionToken[] = [];
        if (stakedAmount.gt(0)) {
          let head = BigNumber.from(0);
          for (let i = BigNumber.from(0); i.lt(stakedAmount); i = i.add(1)) {
            const stakedToken = await stakingContract.userStakedToken(
              account,
              head
            );
            const cid = stakedToken.cid.toNumber();
            userStakedTokens.push({
              id: stakedToken.id,
              cid,
              tokenId: stakedToken.tokenId,
            });
            head = stakedToken.id;

            const collectionContract = getNFTContract(
              collections[cid].address,
              signer ?? fallbackProvider
            );
            const tokenURI = await collectionContract.tokenURI(
              stakedToken.tokenId
            );
            let tokenJson = {
              name: "Error",
              image: "",
            };
            try {
              const response = await fetch(normaliseNFTURI(tokenURI));
              if (response.ok) {
                tokenJson = await response.json();
              } else {
                console.error(
                  `Fetching ${tokenURI} failed - ${response.status}: ${response.statusText}`
                );
              }
            } catch (e) {
              console.error(`Fetching ${tokenURI} failed -`, e);
            }

            userTokens.push({
              tokenId: stakedToken.tokenId,
              image: tokenJson.image,
              name: tokenJson.name,
              collectionId: cid,
            });
          }
        }
        setState({
          stakedNFTs: [...userStakedTokens],
        });

        const collectionApprovals: boolean[] = [];
        for (let i = 0; i < collections.length; i++) {
          const collection = collections[i];
          const collectionContract = getNFTContract(
            collection.address,
            signer ?? fallbackProvider
          );
          const bal = await collectionContract.balanceOf(account);
          for (let j = BigNumber.from(0); j.lt(bal); j = j.add(1)) {
            const tokenId = await collectionContract.tokenOfOwnerByIndex(
              account,
              j
            );
            const tokenURI = await collectionContract.tokenURI(tokenId);
            let tokenJson = {
              name: "Error",
              image: "",
            };
            try {
              const response = await fetch(normaliseNFTURI(tokenURI));
              if (response.ok) {
                tokenJson = await response.json();
              } else {
                console.error(
                  `Fetching ${tokenURI} failed - ${response.status}: ${response.statusText}`
                );
              }
            } catch (e) {
              console.error(`Fetching ${tokenURI} failed -`, e);
            }

            userTokens.push({
              tokenId,
              image: tokenJson.image,
              name: tokenJson.name,
              collectionId: i,
            });
          }

          const approved = await collectionContract.isApprovedForAll(
            account,
            stakingContract.address
          );
          collectionApprovals[i] = approved;
        }
        setState({
          userTokens,
          collectionApprovals,
        });
      } else if (!getState().gotAccount) {
        setState({
          collectionApprovals: undefined,
          userTokens: undefined,
          stakedNFTs: undefined,
        });
      }
    } catch {
      if (!getState().gotAccount || account) {
        console.log("retrying");
        window.setTimeout(getStakingData, 5000);
      }
    }
  }, [account, fallbackProvider, getState, setState, signer, stakingContract]);

  const getStakingScoreData = React.useCallback(async () => {
    if (account) {
      const currentPhase = await stakingContract.currentPhase();
      const score = await stakingContract.userPhaseScore(account, currentPhase);
      const totalScore = await stakingContract.totalPhaseScore(currentPhase);

      setState({
        score,
        totalScore,
      });
    } else {
      setState({
        score: undefined,
        totalScore: undefined,
      });
    }
  }, [account, setState, stakingContract]);

  const setGetStakingData = React.useCallback(
    (getData: boolean) => {
      setState({ getStakingData: getData });
    },
    [setState]
  );

  React.useEffect(() => {
    let interval = -1;
    let quickInterval = -1;
    if (state.getStakingData) {
      getStakingData();
      getStakingScoreData();

      interval = window.setInterval(getStakingData, 60000);
      quickInterval = window.setInterval(getStakingScoreData, 5000);
    }

    return () => {
      if (interval >= 0) clearInterval(interval);
      if (quickInterval >= 0) clearInterval(quickInterval);
    };
  }, [getStakingData, getStakingScoreData, state.getStakingData]);

  const approveNFT = React.useCallback(
    async (cid: number, onComplete: () => any) => {
      if (account && state.nftCollections) {
        setState({
          pendingOpen: true,
        });
        try {
          const collectionContract = getNFTContract(
            state.nftCollections[cid].address,
            signer!
          );
          const estimatedGas =
            await collectionContract.estimateGas.setApprovalForAll(
              stakingContract.address,
              true
            );

          const receipt = await collectionContract.setApprovalForAll(
            stakingContract.address,
            true,
            {
              gasLimit: calculateGasMargin(estimatedGas),
            }
          );

          setState({
            submittedTx: receipt.hash,
          });

          receipt
            .wait()
            .then(() => {
              const freshState = getState();
              const collectionApprovals = freshState.collectionApprovals
                ? [...freshState.collectionApprovals]
                : [];
              collectionApprovals[cid] = true;

              setState({
                successfulTxs: [...freshState.successfulTxs, receipt.hash],
                collectionApprovals,
              });
              onComplete();
            })
            .catch((e) => {
              handleTransactionError(e);
              onComplete();
            });

          return true;
        } catch (e) {
          handleTransactionError(e);
        } finally {
          setState({
            pendingOpen: false,
          });
        }
      }

      return false;
    },
    [
      account,
      getState,
      handleTransactionError,
      setState,
      signer,
      stakingContract.address,
      state.nftCollections,
    ]
  );

  const stakeNFT = React.useCallback(
    async (cid: number, tokenId: BigNumber, onComplete: () => any) => {
      if (account) {
        setState({
          pendingOpen: true,
        });
        try {
          const estimatedGas = await stakingContract.estimateGas.stake(
            cid,
            tokenId
          );

          const receipt = await stakingContract.stake(cid, tokenId, {
            gasLimit: calculateGasMargin(estimatedGas),
          });

          setState({
            submittedTx: receipt.hash,
          });

          receipt
            .wait()
            .then((r) => {
              const freshState = getState();
              const stakeFilter = stakingContract.filters.Stake();

              const stakeLog = r.logs.find(
                (r) => r.topics[0] === stakeFilter.topics![0]
              );

              const newStakedNFTs = freshState.stakedNFTs
                ? [...freshState.stakedNFTs]
                : [];
              if (
                !newStakedNFTs.find((s) =>
                  s.id.eq(BigNumber.from(stakeLog ? stakeLog.data : 0))
                )
              ) {
                newStakedNFTs.push({
                  cid,
                  tokenId,
                  id: BigNumber.from(stakeLog ? stakeLog.data : 0),
                });
              }
              setState({
                successfulTxs: [...freshState.successfulTxs, receipt.hash],
                stakedNFTs: newStakedNFTs,
              });
              onComplete();
            })
            .catch((e) => {
              handleTransactionError(e);
              onComplete();
            });

          return true;
        } catch (e) {
          handleTransactionError(e);
        } finally {
          setState({
            pendingOpen: false,
          });
        }
      }

      return false;
    },
    [account, getState, handleTransactionError, setState, stakingContract]
  );

  const unstakeNFT = React.useCallback(
    async (id: BigNumber, onComplete: () => any) => {
      if (account) {
        setState({
          pendingOpen: true,
        });
        try {
          const estimatedGas = await stakingContract.estimateGas.unstake(id);

          const receipt = await stakingContract.unstake(id, {
            gasLimit: calculateGasMargin(estimatedGas),
          });

          setState({
            submittedTx: receipt.hash,
          });

          receipt
            .wait()
            .then(() => {
              const freshState = getState();
              const newStakedNFTs = [...freshState.stakedNFTs!];
              const indexToRemove = newStakedNFTs.findIndex((s) => s.id.eq(id));
              if (indexToRemove >= 0) {
                newStakedNFTs.splice(indexToRemove, 1);
              }
              setState({
                successfulTxs: [...freshState.successfulTxs, receipt.hash],
                stakedNFTs: newStakedNFTs,
              });
              onComplete();
            })
            .catch((e) => {
              handleTransactionError(e);
              onComplete();
            });

          return true;
        } catch (e) {
          handleTransactionError(e);
        } finally {
          setState({
            pendingOpen: false,
          });
        }
      }

      return false;
    },
    [account, getState, handleTransactionError, setState, stakingContract]
  );

  return {
    state,
    claim,
    closeNotification,
    closePending,
    closeSubmittedTx,
    mintElemental,
    getMintedElemental,
    setGetElementalsData,
    setGetStakingData,
    approveNFT,
    stakeNFT,
    unstakeNFT,
    mintGhosty,
    getMintedGhosty,
    setGetGhostyData,
    claimGhostyRewards,
  };
}
