import * as React from "react";
import { InjectedConnector } from "@web3-react/injected-connector";
import { chainId } from "../constants";

export const InjectedContext = React.createContext<InjectedConnector | null>(
  null
);

export function useInjected() {
  return React.useContext(InjectedContext);
}

export function getInjected() {
  return new InjectedConnector({ supportedChainIds: [chainId] });
}
