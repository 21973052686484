import * as React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { ScrollToTop } from "./ScrollToTop";

export const Layout: React.FC = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      <div className="layout">
        <Header />
        <div className="main">{children}</div>
        <Footer />
      </div>
    </>
  );
};
