import * as React from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutRoute } from "./Components/LayoutRoute";
import { Home } from "./Views/Home";
import { MintElementals } from "./Views/MintElementals";
import { MintGhosty } from "./Views/MintGhosty";
import { MintSelect } from "./Views/MintSelect";
import { Stake } from "./Views/Stake";

export const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <LayoutRoute exact path="/" component={Home} />
      <LayoutRoute exact path="/mint" component={MintSelect} />
      <LayoutRoute exact path="/mint/elementals" component={MintElementals} />
      <LayoutRoute exact path="/mint/ghosty" component={MintGhosty} />
      <LayoutRoute exact path="/stake" component={Stake} />
      <Redirect to="/" />
    </Switch>
  );
};
