import { Provider } from "@ethersproject/providers";
import { Contract, ContractInterface, Signer } from "ethers/lib/ethers";
import * as React from "react";
import {
  tokenAddress,
  elementalsAddress,
  stakingAddress,
  ghostyAddress,
} from "../constants";
import {
  BABYGEIST,
  BABYGEISTDividendTracker,
  Elementals,
  ERC721Stake,
  ERC721Enumerable,
  Ghosty,
} from "../typechain";
import babygeist from "../Abis/babygeist.json";
import dividendTracker from "../Abis/dividendTracker.json";
import elementals from "../Abis/elementals.json";
import erc721stake from "../Abis/erc721stake.json";
import erc721enumerable from "../Abis/erc721enumerable.json";
import ghosty from "../Abis/ghosty.json";

function getContract(
  abi: ContractInterface,
  address: string,
  signerOrProvider: Signer | Provider
) {
  return new Contract(address, abi, signerOrProvider);
}

export function getNFTContract(
  address: string,
  signerOrProvider: Signer | Provider
) {
  return getContract(
    erc721enumerable,
    address,
    signerOrProvider
  ) as ERC721Enumerable;
}

export function useTokenContract(signerOrProvider: Signer | Provider) {
  return React.useMemo(
    () => getContract(babygeist, tokenAddress, signerOrProvider) as BABYGEIST,
    [signerOrProvider]
  );
}

export function useDividendContract(
  address: string | undefined,
  signerOrProvider: Signer | Provider
) {
  return React.useMemo(
    () =>
      address
        ? (getContract(
            dividendTracker,
            address,
            signerOrProvider
          ) as BABYGEISTDividendTracker)
        : undefined,
    [address, signerOrProvider]
  );
}

export function useElementalsContract(signerOrProvider: Signer | Provider) {
  return React.useMemo(
    () =>
      getContract(
        elementals,
        elementalsAddress,
        signerOrProvider
      ) as Elementals,
    [signerOrProvider]
  );
}

export function useGhostyContract(signerOrProvider: Signer | Provider) {
  return React.useMemo(
    () => getContract(ghosty, ghostyAddress, signerOrProvider) as Ghosty,
    [signerOrProvider]
  );
}

export function useStakingContract(signerOrProvider: Signer | Provider) {
  return React.useMemo(
    () =>
      getContract(erc721stake, stakingAddress, signerOrProvider) as ERC721Stake,
    [signerOrProvider]
  );
}
