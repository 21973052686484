export const chainId = 250;
// export const chainId = 4002;
export const chainIdHex = `0x${chainId.toString(16)}`;

export const rpcUrl = "https://rpc.ftm.tools";
// export const rpcUrl = "https://rpc.testnet.fantom.network/";

export const tokenAddress = "0x727465B34ee5C2f4a2b55083A326b3634d2Da6b0";
// export const tokenAddress = "0xA8695A36e8a06f43354c9DFE7F516633EE47c473";

export const elementalsAddress = "0x0710f5eD23C01b33d972BeAcF85A6a5d68Ca6287";

export const ghostyAddress = "0x0981c70Ec2F8B2A3E8aCD28Cc6710e44E1ad8859";
// export const ghostyAddress = "0xcA9BEC2f8F60DcB0D8e2B616AA098055931907AB";

export const stakingAddress = "0xb7D9EA8674b365d4e72404C560e26ABe20E5626c";
// export const stakingAddress = "0x0853FF99FF086abaEf1b179d06B2586d873CECf5";
